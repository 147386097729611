<template>
  <div>
    <div class="calendar-print-header">
      <div class="row m-0">
        <div class="col-4">
          <p class="mb-0" v-if="selectedPatient">
            Client: {{ selectedPatient.last }}, {{ selectedPatient.first }}
          </p>
          <p class="mb-0" v-if="profileAdminUserId && user">
            Admin: {{ user.last }}, {{ user.first }}
          </p>
          <p
            class="mb-0"
            v-if="
              selectedPatient &&
              patientData &&
              companyProfile &&
              companyProfile.show_insurer_in_report &&
              patientData.insurer
            "
          >
            Insurer: {{ patientData.insurer }}
          </p>
          <p
            class="mb-0"
            v-if="
              selectedPatient &&
              patientData &&
              companyProfile &&
              companyProfile.show_claim_number_in_report &&
              patientData.claim_number
            "
          >
            Claim #: {{ patientData.claim_number }}
          </p>
          <p
            class="mb-0"
            v-if="
              selectedPatient &&
              patientData &&
              companyProfile &&
              companyProfile.show_funding_source_in_report &&
              patientData.funding_source
            "
          >
            Funding Source: {{ patientData.funding_source }}
          </p>
          <p class="mb-0" v-if="selectedProvider">
            Admin/Provider: {{ selectedProvider.last }},
            {{ selectedProvider.first }}
          </p>
        </div>
        <div class="col-4 text-center mt-4">
          <img
            class="company-logo"
            v-if="companyProfile"
            :src="companyProfile.logo || companyLogoPlaceholder"
          />
        </div>
        <div class="col-4 text-right">
          <p
            class="mb-0"
            v-if="
              companyProfile &&
              companyProfile.show_claim_number_in_report &&
              companyProfile.claim_number
            "
          >
            <label>Claim Number: </label>{{ companyProfile.claim_number }}
          </p>
          <p
            class="mb-0"
            v-if="
              companyProfile &&
              companyProfile.show_lawyer_in_report &&
              companyProfile.lawyer
            "
          >
            <label>Lawyer: </label>{{ companyProfile.lawyer }}
          </p>
          <p
            class="mb-0"
            v-if="
              companyProfile &&
              companyProfile.show_location_in_report &&
              companyProfile.location
            "
          >
            <label>Location: </label>{{ companyProfile.location }}
          </p>
        </div>
      </div>
    </div>
    <div
      class="print-only"
      v-if="
        !profileProviderUserId && !profilePatientUserId && !profileAdminUserId
      "
    >
      <table class="table table-striped mb-0 calendar-table">
        <thead>
          <tr>
            <div class="calendar-print-table-header"></div>
          </tr>
          <tr>
            <th colspan="9" class="text-center large-font">
              {{ calendarTitle }}
            </th>
          </tr>
          <tr>
            <th>Date</th>
            <th>Duration</th>
            <th>Service</th>
            <th>Provider</th>
            <th>Client</th>
            <th>Reason</th>
            <th>Memo</th>
            <th>Type</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="event in eventsToPrint" :key="`event_${event.id}`">
            <td>{{ dateFormat(event.start, "human") }}</td>
            <td>
              {{
                `${event.hours + getSubServicesTotalHours(event)}

                h${event.minutes > 0 ? " " + event.minutes + "m" : ""}`
              }}
            </td>
            <td>{{ ((event.services || [])[0] || {}).name || "" }}</td>
            <td>{{ event.providerName }}</td>
            <td>{{ event.patientName }}</td>
            <td>{{ event.reason }}</td>
            <td>{{ event.memo }}</td>
            <td class="text-capitalize">{{ event.type }}</td>
            <td class="text-capitalize">{{ event.status }}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>
              <div class="calendar-print-table-footer"></div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
    <div
      class="customCalendarFilter"
      v-if="
        !profileProviderUserId && !profilePatientUserId && !profileAdminUserId
      "
    >
      <div class="row noPrint">
        <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-3" v-if="false">
          <select
            v-model="filter.AppStatus"
            class="form-control shadow-card"
            @change="applyFilter()"
          >
            <option value="" selected>Filter by Appointment Status</option>

            <option v-for="p in appStatuses" :key="p.id" :value="p.id">
              {{ p.label }}
            </option>
          </select>
        </div>
        <div
          class="col-12 col-sm-6 col-md-4 col-lg-3 mb-3"
          v-if="user && !user.isPatient"
        >
          <select
            v-model="filter.classification"
            class="form-control shadow-card"
            @change="applyServerFilter()"
          >
            <option value="" selected>Filter by Class</option>

            <option v-for="p in classes" :key="p.id" :value="p.id">
              {{ p.label }}
            </option>
          </select>
        </div>

        <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-3">
          <select
            v-if="!user || !user.isPatient"
            v-model="filter.Patient"
            class="form-control shadow-card"
            @change="applyServerFilter()"
          >
            <option value="" selected>Filter by Specific User/Client</option>
            <optgroup
              label="Admins"
              v-if="
                user &&
                (user.isAdmin || user.isManagingAdmin) &&
                adminUsers.length
              "
            >
              <option v-for="p in adminUsers" :key="p.id" :value="p.id">
                {{ p.last }}, {{ p.first }}
              </option>
            </optgroup>
            <optgroup
              label="Providers"
              v-if="providerUsers.length && user && !user.isProvider"
            >
              <option
                v-for="p in providerUsers"
                :key="p.id"
                :value="p.user_id || p.id"
              >
                {{ p.last }}, {{ p.first }} {{ p.user_id }}
              </option>
            </optgroup>
            <optgroup label="Clients" v-if="patientUsers.length">
              <option
                v-for="p in patientUsers"
                :key="p.user_id || p.id"
                :value="p.user_id || p.id"
              >
                {{ p.last }}, {{ p.first }}
              </option>
            </optgroup>
          </select>
          <select
            v-if="user && user.isPatient"
            v-model="filter.Provider"
            class="form-control shadow-card"
            @change="applyServerFilter()"
          >
            <option value="" selected>Filter by Specific Provider</option>
            <option
              v-for="p in providerUsers"
              :key="p.id"
              :value="p.user_id || p.id"
            >
              {{ p.last }}, {{ p.first }} {{ p.user_id }}
            </option>
          </select>
        </div>

        <div
          class="col-12 col-sm-6 col-md-4 col-lg-3 mb-3"
          v-if="user && (user.isAdmin || user.isManagingAdmin)"
        >
          <select
            v-model="filter.Provider"
            class="form-control shadow-card"
            @change="applyServerFilter()"
          >
            <option value="" selected>Filter by Specific Provider/Admin</option>

            <optgroup
              label="Admins"
              v-if="
                user &&
                (user.isAdmin || user.isManagingAdmin) &&
                adminUsers.length
              "
            >
              <option v-for="p in adminUsers" :key="p.id" :value="p.id">
                {{ p.last }}, {{ p.first }}
              </option>
            </optgroup>
            <optgroup label="Providers" v-if="providerUsers.length">
              <option v-for="p in providerUsers" :key="p.id" :value="p.id">
                {{ p.last }}, {{ p.first }}
              </option>
            </optgroup>
          </select>
        </div>

        <div
          class="col-12 col-sm-6 col-md-4 col-lg-3 mb-3"
          v-if="user && !user.isPatient"
        >
          <select
            v-model="filter.billableStatus"
            class="form-control shadow-card"
            @change="applyServerFilter()"
          >
            <option value="" selected>Filter by Billable Status</option>

            <option v-for="p in billableStatuses" :key="p.id" :value="p.id">
              {{ p.label }}
            </option>
          </select>
        </div>
        <button
          v-if="user && (user.isAdmin || user.isManagingAdmin)"
          class="btn btn-deep-success ml-3 mb-3"
          @click="openBulkSessionsModal()"
        >
          Verify/Unverify Appointments
        </button>
        <button
          v-if="user && (user.isAdmin || user.isManagingAdmin)"
          class="btn btn-1a3f3f ml-3 mb-3"
          @click="openUsersAvailability()"
        >
          Users Availability
        </button>
      </div>
    </div>
    <div
      class="customCalendarFilter d-flex flex-wrap mt-4 align-items-center"
      v-if="
        user &&
        (profileAdminUserId ||
          (profilePatientUserId && !user.isPatient) ||
          (profileProviderUserId && (user.isAdmin || user.isManagingAdmin)))
      "
    >
      <div
        class="custom-control custom-checkbox form-group mr-3"
        v-if="
          user &&
          ((profilePatientUserId && !user.isPatient) ||
            (profileProviderUserId && (user.isAdmin || user.isManagingAdmin)))
        "
      >
        <input
          type="checkbox"
          class="custom-control-input"
          id="calendar_assignedToMe"
          v-model="assignedToMe"
          @change="applyServerFilter()"
        />
        <label class="custom-control-label" for="calendar_assignedToMe"
          >Assigned to me ({{ user.name }}) only</label
        >
      </div>
      <button
        v-if="user && (user.isAdmin || user.isManagingAdmin)"
        class="btn btn-deep-success mb-4"
        @click="openBulkSessionsModal()"
      >
        Verify/Unverify Appointments
      </button>
      <button
        v-if="user && (user.isAdmin || user.isManagingAdmin)"
        class="btn btn-1a3f3f ml-3 mb-4"
        @click="openUserAvailability()"
      >
        {{
          getUserName(
            profileAdminUserId || profileProviderUserId || profilePatientUserId
          )
        }}'s Availability
      </button>
    </div>
    <p class="mobile-tablet-only text-danger noPrint">
      Hint: Long press to create a session
    </p>
    <div
      class="calendar-wrapper"
      :class="
        !profileProviderUserId && !profilePatientUserId && !profileAdminUserId
          ? 'noPrint'
          : ''
      "
    >
      <button
        type="button"
        class="btn btn-outline-blue noPrint print-btn"
        @click="print"
      >
        <i class="fas fa-print mr-2"></i> Print
      </button>
      <div class="total-hours-info" v-if="user && !user.isPatient">
        <table>
          <tr>
            <td class="text-right pr-2">Total Billable:</td>
            <td class="text-right font-weight-bold">
              <i v-if="loading" class="fas fa-spin fa-circle-notch"></i
              >{{ loading ? "" : totalBillable }}
            </td>
          </tr>
          <tr>
            <td class="text-right pr-2">Total Non Billable:</td>
            <td class="text-right font-weight-bold">
              <i v-if="loading" class="fas fa-spin fa-circle-notch"></i
              >{{ loading ? "" : totalNonBillable }}
            </td>
          </tr>
        </table>
        <i
          class="ml-4 fas fa-lg pointer-cursor noPrint"
          style="width: 30px"
          :class="`${hideDeleted ? 'fa-eye-slash' : 'fa-eye'}`"
          @click="toggleDeletedStatus()"
          :title="
            hideDeleted
              ? 'Removed sessions are hidden'
              : 'Removed sessions are visible'
          "
        ></i>
      </div>
      <full-calendar ref="fullCalendar" :options="calendar" />
      <loader v-if="loading || gettingUsers"></loader>
    </div>
    <!-- Appointment Create start -->
    <sidebar :toggle="openTray" @close="close">
      <loader v-if="openTray && actionInProgress" class="event-loader"></loader>
      <form validate @submit.prevent="handleBook" v-if="openTray">
        <div class="align-items-start d-flex">
          <h2>Book New Appointment</h2>
          <i
            class="fas fa-lg fa-user-secret ml-5"
            v-if="isHidden && user && (user.isAdmin || user.isManagingAdmin)"
          ></i>
        </div>

        <nav>
          <div class="nav nav-tabs" id="nav-create-tab" role="tablist">
            <a
              class="nav-item nav-link active"
              id="nav-home-create-tab"
              data-toggle="tab"
              href="#nav-create-home"
              role="tab"
              aria-controls="nav-create-home"
              aria-selected="true"
              >Details</a
            >
            <a
              class="nav-item nav-link"
              id="nav-memo-create-tab"
              data-toggle="tab"
              href="#nav-create-memo"
              role="tab"
              aria-controls="nav-create-memo"
              aria-selected="false"
              >Memo & Reason</a
            >
            <a
              v-if="user && !user.isPatient && booking.primary && isBillable"
              class="nav-item nav-link"
              id="nav-profile-create-tab"
              data-toggle="tab"
              href="#nav-create-profile"
              role="tab"
              aria-controls="nav-create-profile"
              aria-selected="false"
              >Sub Services</a
            >
            <a
              class="nav-item nav-link"
              id="nav-expenses-create-tab"
              data-toggle="tab"
              href="#nav-create-expenses"
              v-if="isBillable"
              role="tab"
              aria-controls="nav-create-expenses"
              aria-selected="false"
              >Expenses</a
            >
          </div>
        </nav>
        <div class="tab-content" id="nav-create-tabContent">
          <div
            class="tab-pane fade show active"
            id="nav-create-home"
            role="tabpanel"
            aria-labelledby="nav-home-create-tab"
          >
            <div class="row">
              <div class="col-12 row m-0 mb-3 mb-sm-0">
                <label>Is this billable or non-billable?</label>
                <div class="custom-control custom-switch ml-4 ml-sm-5 mr-sm-2">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="isBillable"
                    v-model="isBillable"
                    @change="billableStatusChanged(true)"
                  />
                  <label
                    class="custom-control-label pointer-cursor"
                    for="isBillable"
                    >{{ isBillable ? "Billable" : "Non Billable" }}</label
                  >
                </div>
              </div>
              <div
                class="col-12 row m-0 mb-3 mb-sm-0"
                v-if="user && (user.isAdmin || user.isManagingAdmin)"
              >
                <label class="mr-5 pr-5"
                  >Is this hidden?
                  <i
                    class="fa fa-info-circle ml-2 pointer-cursor"
                    v-tooltip.top="
                      'Once the session is marked as hidden, only admins and managers can see it.'
                    "
                  ></i>
                </label>
                <div class="custom-control custom-switch ml-4 ml-sm-5 mr-sm-2">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="isHidden"
                    v-model="isHidden"
                  />
                  <label
                    class="custom-control-label pointer-cursor"
                    for="isHidden"
                    >{{ isHidden ? "Hidden" : "Visible" }}</label
                  >
                </div>
              </div>
              <div class="col-12">
                <label>Appointment Time</label>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <datetime
                        v-model="clickedEventDetails_startStr"
                        @change="setChangesMade"
                        placeholder="DateTime"
                        :week-start="7"
                        input-class="col-md-12 form-control"
                        input-style="font-size: 12px;"
                        type="datetime"
                      >
                      </datetime>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <datetime
                        v-model="clickedEventDetails_endStr"
                        @change="setChangesMade"
                        placeholder="DateTime"
                        :week-start="7"
                        input-class="col-md-12 form-control"
                        input-style="font-size: 12px;"
                        type="datetime"
                      >
                      </datetime>
                      <div
                        class="text-right pr-2"
                        :style="`opacity: ${
                          selectedMainServiceOnCreate &&
                          selectedMainServiceOnCreate.unit !== 'FT' &&
                          selectedMainServiceOnCreate.unit !== 'FLAT'
                            ? '1'
                            : '0'
                        }`"
                      >
                        <span
                          class="
                            small
                            text-primary
                            font-weight-bold
                            pointer-cursor
                          "
                          @click="setTimeToZero()"
                          >Set main service to zero hrs</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div
                  class="form-group"
                  v-if="user && (user.isAdmin || user.isManagingAdmin)"
                >
                  <label>Provider/Admin</label>
                  <select
                    required
                    v-model="booking.provider"
                    class="form-control"
                    @change="
                      (booking.provider = $event.target.value),
                        showServicesBasedOnSelectedProvider(
                          $event.target.value,
                          true
                        ),
                        updateProviderRate($event.target.value, booking.primary)
                    "
                  >
                    <optgroup label="Admins" v-if="adminUsers.length">
                      <option
                        v-for="p in adminUsers"
                        :disabled="p.status == 'INACTIVE'"
                        :key="p.id"
                        :value="p.id + '_' + (p.isAdmin || p.isManagingAdmin)"
                      >
                        {{ p.last }}, {{ p.first }}
                      </option>
                    </optgroup>
                    <optgroup label="Providers" v-if="providerUsers.length">
                      <option
                        v-for="p in providerUsers"
                        :disabled="p.status == 'INACTIVE'"
                        :key="p.id"
                        :value="p.id + '_' + (p.isAdmin || p.isManagingAdmin)"
                      >
                        {{ p.last }}, {{ p.first }}
                      </option>
                    </optgroup>
                  </select>
                  <div
                    class="
                      px-1
                      mb-2
                      pt-2
                      d-flex
                      justify-content-between
                      align-items-center
                    "
                  >
                    <a
                      v-if="!isBillable"
                      href="#"
                      class="h4 mb-0"
                      @click.prevent="
                        (booking.provider = `${user.id}_${
                          user.isAdmin ? 'true' : 'false'
                        }`),
                          showServicesBasedOnSelectedProvider(
                            `${user.id}_${user.isAdmin ? 'true' : 'false'}`,
                            true
                          ),
                          updateProviderRate(
                            `${user.id}_${user.isAdmin ? 'true' : 'false'}`,
                            booking.primary
                          )
                      "
                      >Assign to me</a
                    >
                    <a
                      href="#"
                      v-if="booking.provider"
                      class="h4 mb-0 ml-auto"
                      @click.prevent="
                        openUserAvailabilityModal(booking.provider)
                      "
                      >Availability</a
                    >
                  </div>
                </div>
                <div class="form-group">
                  <label>{{ isBillable ? "Client" : "User/Client" }}</label>
                  <select
                    :required="isBillable"
                    v-model="booking.patient"
                    @change="
                      showServicesBasedOnSelectedProvider(
                        booking.provider,
                        true
                      )
                    "
                    @input="setChangesMade"
                    class="form-control"
                  >
                    <optgroup
                      label="Admins"
                      v-if="
                        !isBillable &&
                        user &&
                        (user.isAdmin || user.isManagingAdmin)
                      "
                    >
                      <option
                        v-for="p in adminUsers"
                        :key="p.id"
                        :value="p.id"
                        :disabled="p.status == 'INACTIVE'"
                      >
                        {{ p.last }}, {{ p.first }}
                      </option>
                    </optgroup>
                    <optgroup
                      label="Providers"
                      v-if="!isBillable && providerUsers.length"
                    >
                      <option
                        v-for="p in providerUsers"
                        :disabled="p.status == 'INACTIVE'"
                        :key="p.id"
                        :value="p.id"
                      >
                        {{ p.last }}, {{ p.first }}
                      </option>
                    </optgroup>
                    <optgroup
                      label="Clients"
                      v-if="!isBillable && patientUsers.length"
                    >
                      <option
                        v-for="p in patientUsers"
                        :disabled="p.status == 'INACTIVE'"
                        :key="p.user_id || p.id"
                        :value="p.user_id || p.id"
                      >
                        {{ p.last }}, {{ p.first }}
                      </option>
                    </optgroup>
                    <template v-if="isBillable">
                      <option
                        v-for="p in displayedPatients"
                        :disabled="p.status == 'INACTIVE'"
                        :key="p.user_id || p.id"
                        :value="p.user_id || p.id"
                      >
                        {{ p.name ? p.name : `${p.last}, ${p.first}` }}
                      </option>
                    </template>
                  </select>
                  <div
                    class="
                      px-1
                      mb-2
                      pt-2
                      d-flex
                      justify-content-between
                      align-items-center
                    "
                  >
                    <a
                      v-if="!isBillable"
                      href="#"
                      class="h4 mb-0"
                      @click.prevent="
                        (booking.patient = user.id), setChangesMade()
                      "
                      >Assign to me</a
                    >
                    <a
                      href="#"
                      class="h4 mb-0 ml-auto"
                      v-if="booking.patient"
                      @click.prevent="
                        openUserAvailabilityModal(booking.patient)
                      "
                      >Availability</a
                    >
                  </div>
                </div>
                <div class="form-group">
                  <label>Service</label>
                  <select
                    required
                    v-model="booking.primary"
                    class="form-control"
                    @change="
                      getSubServicesByParentServiceId($event.target.value, null)
                    "
                  >
                    <option
                      v-for="p in parentServices"
                      :key="p.id"
                      :value="p.id"
                    >
                      {{ p.name }} {{ p.catastrophic ? "(CAT)" : "" }}
                    </option>
                  </select>
                  <div
                    class="w-100 text-right"
                    v-if="
                      user && !user.isPatient && booking.primary && isBillable
                    "
                  >
                    <a
                      href="#nav-create-profile"
                      class="h5 mr-3"
                      @click.prevent="selectTab('#nav-profile-create-tab')"
                      >Add sub service</a
                    >
                  </div>
                </div>

                <input-control
                  control="number"
                  v-model="booking.provider_rate"
                  @change="setChangesMade"
                  @input="setChangesMade"
                  type="vertical"
                  step="0.1"
                  min="0"
                  >Provider Rate</input-control
                >

                <div class="form-group">
                  <label>Appointment Type</label>
                  <select
                    required
                    v-model="booking.type"
                    class="form-control"
                    @change="showHideAutoVirtualMeeting($event.target.value)"
                  >
                    <option
                      v-for="p in displayedServiceTypes"
                      :key="p.value"
                      :value="p.value"
                    >
                      {{ p.label }}
                    </option>
                  </select>
                </div>

                <div class="form-group" v-if="showAutoScheduleOption">
                  <label>Auto-Schedule Virtual Meeting</label>
                  <div class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      v-model="booking.auto_schedule_zoom"
                      @change="setChangesMade"
                      @input="setChangesMade"
                      id="customCheck1"
                    />
                    <label class="custom-control-label" for="customCheck1"
                      >Yes, schedule a virtual meeting</label
                    >
                  </div>
                </div>
              </div>
              <div
                class="col-12 mb-2"
                v-if="
                  user &&
                  (user.isAdmin ||
                    user.isManagingAdmin ||
                    (currentProvider && currentProvider.can_verify_event))
                "
              >
                <input
                  type="checkbox"
                  id="verifyBookEvent"
                  v-model="verifyBookEvent"
                  @change="setChangesMade"
                />
                <label for="verifyBookEvent" class="pointer-cursor ml-2"
                  >Verify This Event</label
                >
              </div>
              <div class="col-12 mb-2">
                <input
                  type="checkbox"
                  id="allowOverlap"
                  v-model="allowOverlap"
                  @change="setChangesMade"
                />
                <label
                  for="allowOverlap"
                  class="pointer-cursor ml-2 text-dark-red"
                  >Allow Overlapping With Other Sessions</label
                >
              </div>
              <div class="col-12">
                <button class="btn btn-block btn-success" type="submit">
                  Book
                </button>
              </div>
            </div>
          </div>
          <!-- end of first tab -->

          <div
            class="tab-pane fade"
            id="nav-create-profile"
            role="tabpanel"
            aria-labelledby="nav-profile-create-tab"
          >
            <label class="serviceName"
              >{{
                this.booking.primary != null
                  ? ""
                  : "To add sub services, please select a Service first"
              }}
            </label>
            <div
              v-if="
                clickedEventDetails_services != null &&
                clickedEventDetails_services.length != 0
              "
            >
              <span style="color: #f00; font-size: 12px"
                >* Only the selected Sub Service will be submitted</span
              >

              <div
                class="subServiceItem col-md-12"
                v-for="subService in clickedEventDetails_services"
                :key="subService.id"
              >
                <div class="row mb-2 align-items-center">
                  <input
                    class="form-control pointer-cursor checkbox-input"
                    type="checkbox"
                    :id="`${subService.name}_${subService.id}`"
                    v-model="subService.selected"
                    @change="setChangesMade"
                    :disabled="subService.is_default == true"
                  />
                  <label
                    :for="`${subService.name}_${subService.id}`"
                    class="pointer-cursor ml-2 mb-0"
                    >Selected <span style="color: #f00">*</span></label
                  >
                </div>

                <div
                  class="subServiceItemContainer"
                  :class="
                    subService.something == false ? 'disabledSubService1' : ''
                  "
                >
                  <h3 class="SubServiceTitle">{{ subService.name }}</h3>

                  <div class="row mr-0">
                    <div class="col-md-4" v-if="user && !user.isProvider">
                      <input-control
                        type="vertical"
                        v-model="subService.rate"
                        control="number"
                        step="0.01"
                        disabled
                        @input="setChangesMade"
                        >Rate</input-control
                      >
                    </div>

                    <div
                      class="mt-3 mt-md-0"
                      :class="`${
                        user && user.isProvider ? 'col-md-6' : 'col-md-4'
                      }`"
                    >
                      <input-control
                        type="vertical"
                        v-model="subService.qty"
                        @input="setChangesMade"
                        @change="setChangesMade"
                        :required="subService.selected"
                        :disabled="
                          subService.unit == 'FT' || subService.unit == 'FLAT'
                        "
                        step="0.01"
                        control="number"
                        >Quantity</input-control
                      >
                    </div>

                    <div
                      class="mt-3 mt-md-0"
                      :class="`${
                        user && user.isProvider ? 'col-md-6' : 'col-md-4'
                      }`"
                    >
                      <select-control
                        type="vertical"
                        v-model="subService.unit"
                        @change="setChangesMade"
                        @input="setChangesMade"
                        labelClass="mr-0"
                        disabled
                        :options="units"
                        >Unit</select-control
                      >
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-md-6" v-if="false">
                      <div class="row">
                        <label class="col-md-12">Taxable</label>
                      </div>
                      <input
                        type="radio"
                        id="yes"
                        value="true"
                        v-model="subService.taxable"
                        @change="setChangesMade"
                        @input="setChangesMade"
                      />
                      <label for="yes">Yes</label>
                      <input
                        type="radio"
                        id="no"
                        value="false"
                        @change="setChangesMade"
                        @input="setChangesMade"
                        v-model="subService.taxable"
                      />
                      <label for="no">No</label>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-md-12">
                      <input-control
                        type="vertical"
                        rows="6"
                        v-model="subService.description"
                        @change="setChangesMade"
                        @input="setChangesMade"
                        maxLength="500"
                        control="textarea"
                        >Description
                      </input-control>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-100 text-center mb-5">
              <a
                class="btn btn-success"
                href="#nav-create-home"
                @click.prevent="selectTab('#nav-home-create-tab')"
                >Back To Details</a
              >
            </div>
          </div>
          <!-- end of sub services tab-->
          <div
            class="tab-pane fade"
            id="nav-create-memo"
            role="tabpanel"
            aria-labelledby="nav-memo-create-tab"
          >
            <div class="form-group">
              <label>Reason - Seen by client</label>
              <textarea
                class="form-control"
                v-model="booking.reason"
                @change="setChangesMade"
                rows="5"
              ></textarea>
            </div>
            <div class="form-group" v-if="user && !user.isPatient">
              <label>Memo</label>
              <textarea
                class="form-control"
                v-model="booking.memo"
                @change="setChangesMade"
                rows="5"
              ></textarea>
            </div>
            <div class="w-100 text-center mb-5">
              <a
                class="btn btn-success"
                href="#nav-create-home"
                @click.prevent="selectTab('#nav-home-create-tab')"
                >Back To Details</a
              >
            </div>
          </div>
          <!-- end of memo and reason tab-->
          <div
            class="tab-pane fade"
            id="nav-create-expenses"
            role="tabpanel"
            aria-labelledby="nav-expenses-create-tab"
          >
            <div class="form-group">
              <input-control
                control="number"
                v-model="booking.expenses"
                @input="setChangesMade"
                type="vertical"
                step="0.01"
                >Expenses $</input-control
              >
              <input-control
                type="vertical"
                rows="6"
                v-model="booking.billing_details"
                @change="setChangesMade"
                @input="setChangesMade"
                control="textarea"
                >Billing Details
              </input-control>
            </div>
            <div class="w-100 text-center mb-5">
              <a
                class="btn btn-success"
                href="#nav-create-home"
                @click.prevent="selectTab('#nav-home-create-tab')"
                >Back To Details</a
              >
            </div>
          </div>
          <!-- end of expenses tab-->
        </div>
        <!-- end of tabs cotainers -->
      </form>
    </sidebar>
    <!-- appointment Create End -->

    <!-- appointment Update Start -->
    <sidebar :toggle="openUpdateTray" @close="closeUpdateTray">
      <loader
        v-if="openUpdateTray && actionInProgress"
        class="event-loader"
      ></loader>
      <div v-if="openUpdateTray">
        <div class="row">
          <div class="mr-2 mb-3">
            <button
              v-if="
                clickedEventDetails_meetingUrl != null &&
                !clickedEventDetails_deleted &&
                canEnable(clickedEventDetails_provider_user_id)
              "
              class="btn btn-secondary active"
              @click="joinMeeting()"
            >
              Join Virtual Meeting
            </button>
          </div>
          <div
            class="mr-2"
            v-if="
              user &&
              !user.isPatient &&
              !clickedEventDetails_deleted &&
              canEnable(clickedEventDetails_provider_user_id)
            "
          >
            <button
              class="btn btn-info mb-3"
              @click="UpdateBook()"
              :disabled="clickedEventDetails_status == 'Verified'"
            >
              Save Changes
            </button>
          </div>

          <div
            v-if="
              (clickedEventDetails_status == 'Ready for Review' ||
                clickedEventDetails_status == 'PENDING') &&
              !clickedEventDetails_deleted &&
              canEnable(clickedEventDetails_provider_user_id)
            "
          >
            <a
              class="btn btn-info mb-2"
              v-if="
                user &&
                (user.isAdmin ||
                  user.isManagingAdmin ||
                  (currentProvider && currentProvider.can_verify_event))
              "
              @click="approveEvent"
            >
              <span
                class="spinner-border spinner-border-sm"
                v-if="loading"
              ></span>
              Verify
            </a>
          </div>

          <div
            v-if="
              clickedEventDetails_status == 'Verified' &&
              !clickedEventDetails_deleted &&
              canEnable(clickedEventDetails_provider_user_id)
            "
          >
            <a
              class="btn btn-warning mb-2"
              v-if="user && (user.isAdmin || user.isManagingAdmin)"
              @click="unapproveEvent"
            >
              Un-Verify
            </a>
          </div>
          <h2
            v-if="
              !clickedEventDetails_deleted &&
              !canEnable(clickedEventDetails_provider_user_id) &&
              clickedEventDetails_type != null
            "
            class="col-12 mt-2 mb-0 pl-2"
            :class="`${
              clickedEventDetails_status != null
                ? clickedEventDetails_status == 'PENDING'
                  ? 'text-warning'
                  : clickedEventDetails_status == 'Verified'
                  ? 'text-success'
                  : clickedEventDetails_status
                : 'text-warning'
            }`"
          >
            {{
              clickedEventDetails_status != null
                ? clickedEventDetails_status == "PENDING"
                  ? "Pending"
                  : clickedEventDetails_status == "Verified"
                  ? "Verified"
                  : clickedEventDetails_status
                : "Pending"
            }}
          </h2>
          <div
            v-if="
              user &&
              (user.isAdmin ||
                user.isManagingAdmin ||
                (user.isProvider && clickedEventDetails_status != 'Verified'))
            "
          >
            <button
              class="btn btn-danger ml-2 mr-2 mb-3"
              @click="DeleteEvent"
              v-if="
                !clickedEventDetails_deleted &&
                canEnable(clickedEventDetails_provider_user_id)
              "
            >
              Delete
            </button>

            <i
              class="fas fa-xl fa-user-secret ml-4"
              v-if="clickedEventDetails_hidden"
            ></i>

            <h1
              v-if="clickedEventDetails_deleted"
              class="text-danger font-weight-bold mb-0"
              style="letter-spacing: 2px"
            >
              REMOVED
            </h1>
          </div>
        </div>
        <div class="row"></div>
        <div class="row">
          <h3
            class="col-md-12 mt-4"
            v-if="
              clickedEventDetails_patient != null &&
              clickedEventDetails_type != null
            "
          >
            {{ clickedEventDetails_type }} Appointment with
            {{ clickedEventDetails_patient.last }},
            {{ clickedEventDetails_patient.first }}
          </h3>
        </div>
        <div
          class="row"
          v-if="
            !clickedEventDetails_deleted &&
            canEnable(clickedEventDetails_provider_user_id)
          "
        >
          <h2
            class="col-md-6 mt-4"
            :class="`${
              clickedEventDetails_status != null
                ? clickedEventDetails_status == 'PENDING'
                  ? 'text-warning'
                  : clickedEventDetails_status == 'Verified'
                  ? 'text-success'
                  : clickedEventDetails_status
                : 'text-warning'
            }`"
            v-if="clickedEventDetails_type != null"
          >
            {{
              clickedEventDetails_status != null
                ? clickedEventDetails_status == "PENDING"
                  ? "Pending"
                  : clickedEventDetails_status == "Verified"
                  ? "Verified"
                  : clickedEventDetails_status
                : "Pending"
            }}
          </h2>
          <div
            v-if="user && !user.isPatient"
            class="
              col-md-6
              text-right
              align-items-center
              d-flex
              flex-column
              justify-content-end
            "
          >
            <button
              type="button"
              class="btn btn-lg btn-primary"
              @click="createNote"
              v-if="canHaveClinicalNotes()"
            >
              Add Clinical Note
            </button>
            <a
              href="/"
              v-if="canHaveClinicalNotes()"
              class="h5 mb-0 mt-3 text-underline"
              @click.prevent="navigateToClinicalNotes()"
              ><u>Show Client Notes</u></a
            >
          </div>
        </div>
        <div
          class="row mt-2 mx-0 align-items-center"
          v-if="
            !user.isPatient &&
            !clickedEventDetails_deleted &&
            canEnable(clickedEventDetails_provider_user_id)
          "
        >
          <button
            type="button"
            class="btn btn-sm btn-warning font-weight-bold dropdown-toggle"
            id="calendar-button"
            ref="externalCalendarButton"
            @click="addToExternalCalendar"
          >
            <i class="far fa-calendar-plus mr-2 mt-2"></i> Add To External
            Calendar
          </button>
          <i
            class="fa fa-info-circle ml-2 pointer-cursor"
            v-tooltip.top="
              `Make sure your calendar account's timezone matches the current device's timezone (${currentTimeZone}) to avoid time difference issues.`
            "
          ></i>
        </div>
        <div
          class="row mt-3"
          v-if="user && (user.isAdmin || user.isManagingAdmin)"
        >
          <div
            class="col-12 row m-0 mb-3 mb-sm-0"
            v-if="
              clickedEventDetails_status != 'Verified' &&
              user &&
              (user.isAdmin || user.isManagingAdmin)
            "
          >
            <label class="mr-5"
              >Is this hidden?
              <i
                class="fa fa-info-circle ml-2 pointer-cursor"
                v-tooltip.top="
                  'once the session marked as hidden then only admins and managers can see it'
                "
              ></i>
            </label>
            <div class="custom-control custom-switch ml-4 ml-sm-5 mr-sm-2">
              <input
                type="checkbox"
                class="custom-control-input"
                id="isHidden"
                v-model="clickedEventDetails_hidden"
              />
              <label
                class="custom-control-label pointer-cursor"
                for="isHidden"
                >{{ clickedEventDetails_hidden ? "Hidden" : "Visible" }}</label
              >
            </div>
          </div>
        </div>
        <nav>
          <div class="nav nav-tabs" id="nav-tab" role="tablist">
            <a
              class="nav-item nav-link active"
              id="nav-home-tab"
              data-toggle="tab"
              href="#nav-home"
              role="tab"
              aria-controls="nav-home"
              aria-selected="true"
              >Details</a
            >
            <a
              class="nav-item nav-link"
              id="nav-memo-tab"
              data-toggle="tab"
              href="#nav-memo"
              role="tab"
              aria-controls="nav-memo"
              aria-selected="false"
              >Memo & Reason</a
            >
            <a
              v-if="
                user &&
                !user.isPatient &&
                clickedEventDetails_service.id &&
                isBillable
              "
              class="nav-item nav-link"
              id="nav-profile-tab"
              data-toggle="tab"
              href="#nav-profile"
              role="tab"
              aria-controls="nav-profile"
              aria-selected="false"
              >Sub Services</a
            >
            <a
              v-if="user && !user.isPatient && isBillable"
              class="nav-item nav-link"
              id="nav-expenses-tab"
              data-toggle="tab"
              href="#nav-expenses"
              role="tab"
              aria-controls="nav-expenses"
              aria-selected="false"
              >Expenses</a
            >
          </div>
        </nav>
        <div class="tab-content" id="nav-tabContent">
          <div
            class="tab-pane fade show active"
            id="nav-home"
            role="tabpanel"
            aria-labelledby="nav-home-tab"
          >
            <div class="row">
              <label class="col-12">Appointment Time</label>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <datetime
                    v-model="clickedEventDetails_startStr"
                    @input="setChangesMade"
                    :disabled="
                      clickedEventDetails_status == 'Verified' ||
                      clickedEventDetails_deleted ||
                      !canEnable(clickedEventDetails_provider_user_id)
                    "
                    placeholder="DateTime"
                    :week-start="7"
                    input-class="col-md-12 form-control"
                    input-style="font-size: 12px;"
                    type="datetime"
                  >
                  </datetime>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <datetime
                    v-model="clickedEventDetails_endStr"
                    @input="setChangesMade"
                    :disabled="
                      clickedEventDetails_status == 'Verified' ||
                      clickedEventDetails_deleted ||
                      !canEnable(clickedEventDetails_provider_user_id)
                    "
                    placeholder="DateTime"
                    :week-start="7"
                    input-class="col-md-12 form-control"
                    input-style="font-size: 12px;"
                    type="datetime"
                  >
                  </datetime>
                  <div
                    class="text-right pr-2"
                    :style="`opacity: ${
                      clickedEventDetails_mainService &&
                      clickedEventDetails_mainService.unit !== 'FT' &&
                      clickedEventDetails_mainService.unit !== 'FLAT'
                        ? '1'
                        : '0'
                    }`"
                  >
                    <span
                      v-if="
                        clickedEventDetails_status != 'Verified' &&
                        !clickedEventDetails_deleted &&
                        canEnable(clickedEventDetails_provider_user_id)
                      "
                      class="small text-primary font-weight-bold pointer-cursor"
                      @click="setTimeToZero()"
                      >Set main service to zero hrs</span
                    >
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div
                  class="form-group"
                  v-if="clickedEventDetails_provider != null"
                >
                  <label>Provider/Admin</label>
                  <select
                    @change="
                      (clickedEventDetails_provider_user_id =
                        $event.target.value),
                        showServicesBasedOnSelectedProvider($event.target.value)
                    "
                    :disabled="
                      (user && (user.isPatient || user.isProvider)) ||
                      clickedEventDetails_status == 'Verified' ||
                      clickedEventDetails_deleted ||
                      !canEnable(clickedEventDetails_provider_user_id)
                    "
                    v-model="clickedEventDetails_provider_user_id"
                    class="form-control"
                  >
                    <optgroup label="Admins">
                      <option
                        v-for="p in adminUsers"
                        :key="p.id"
                        :value="p.id"
                        :disabled="p.status == 'INACTIVE'"
                      >
                        {{ p.last }}, {{ p.first }}
                      </option>
                    </optgroup>
                    <optgroup label="Providers" v-if="providerUsers.length">
                      <option
                        v-for="p in providerUsers"
                        :disabled="p.status == 'INACTIVE'"
                        :key="p.id"
                        :value="p.id"
                      >
                        {{ p.last }}, {{ p.first }}
                      </option>
                    </optgroup>
                  </select>
                  <div
                    class="
                      px-1
                      mb-2
                      pt-2
                      d-flex
                      justify-content-between
                      align-items-center
                    "
                  >
                    <a
                      v-if="
                        user &&
                        (user.isAdmin || user.isManagingAdmin) &&
                        !isBillable &&
                        clickedEventDetails_status != 'Verified' &&
                        !clickedEventDetails_deleted &&
                        canEnable(clickedEventDetails_provider_user_id)
                      "
                      href="#"
                      class="h4 mb-0"
                      @click.prevent="
                        (clickedEventDetails_provider_user_id = user.id),
                          showServicesBasedOnSelectedProvider(user.id)
                      "
                      >Assign to me</a
                    >
                    <a
                      href="#"
                      class="h4 mb-0 ml-auto"
                      v-if="clickedEventDetails_provider_user_id"
                      @click.prevent="
                        openUserAvailabilityModal(
                          clickedEventDetails_provider_user_id
                        )
                      "
                      >Availability</a
                    >
                  </div>
                </div>
                <div
                  class="form-group"
                  v-if="
                    clickedEventDetails_patient != null &&
                    user &&
                    (user.isProvider || user.isAdmin || user.isManagingAdmin)
                  "
                >
                  <label>{{ isBillable ? "Client" : "User" }}</label>
                  <select
                    v-model="clickedEventDetails_patient_user_id"
                    :disabled="
                      clickedEventDetails_status == 'Verified' ||
                      clickedEventDetails_deleted ||
                      !canEnable(clickedEventDetails_provider_user_id)
                    "
                    @change="
                      showServicesBasedOnSelectedProvider(
                        clickedEventDetails_provider_user_id
                      )
                    "
                    class="form-control"
                  >
                    <optgroup
                      label="Admins"
                      v-if="!isBillable && user && !user.isPatient"
                    >
                      <option
                        v-for="p in adminUsers"
                        :key="p.id"
                        :value="p.id"
                        :disabled="p.status == 'INACTIVE'"
                      >
                        {{ p.last }}, {{ p.first }}
                      </option>
                    </optgroup>
                    <optgroup
                      label="Providers"
                      v-if="!isBillable && providerUsers.length"
                    >
                      <option
                        v-for="p in providerUsers"
                        :disabled="p.status == 'INACTIVE'"
                        :key="p.id"
                        :value="p.id"
                      >
                        {{ p.last }}, {{ p.first }}
                      </option>
                    </optgroup>
                    <optgroup
                      label="Clients"
                      v-if="!isBillable && patientUsers.length"
                    >
                      <option
                        v-for="p in patientUsers"
                        :disabled="p.status == 'INACTIVE'"
                        :key="p.user_id || p.id"
                        :value="p.user_id || p.id"
                      >
                        {{ p.last }}, {{ p.first }}
                      </option>
                    </optgroup>
                    <template v-if="isBillable">
                      <option
                        v-for="p in displayedPatients"
                        :disabled="p.status == 'INACTIVE'"
                        :key="p.user_id || p.id"
                        :value="p.user_id || p.id"
                      >
                        {{ `${p.last}, ${p.first}` }}
                      </option>
                    </template>
                  </select>
                  <div
                    class="
                      px-1
                      mb-2
                      pt-2
                      d-flex
                      justify-content-between
                      align-items-center
                    "
                  >
                    <a
                      v-if="
                        !isBillable &&
                        clickedEventDetails_status != 'Verified' &&
                        !clickedEventDetails_deleted &&
                        canEnable(clickedEventDetails_provider_user_id)
                      "
                      href="#"
                      class="h5 ml-2"
                      @click.prevent="
                        (clickedEventDetails_patient_user_id = user.id),
                          setChangesMade()
                      "
                      >Assign to me</a
                    >
                    <a
                      href="#"
                      class="h4 mb-0 ml-auto"
                      v-if="clickedEventDetails_patient_user_id"
                      @click.prevent="
                        openUserAvailabilityModal(
                          clickedEventDetails_patient_user_id
                        )
                      "
                      >Availability</a
                    >
                  </div>
                </div>
                <div
                  class="form-group"
                  v-if="
                    clickedEventDetails_provider != null &&
                    user &&
                    !user.isProvider
                  "
                >
                  <label>Appointment Type</label>
                  <select
                    :disabled="
                      (user && user.isPatient) ||
                      clickedEventDetails_status == 'Verified' ||
                      clickedEventDetails_deleted ||
                      !canEnable(clickedEventDetails_provider_user_id)
                    "
                    required
                    v-model="clickedEventDetails_type"
                    @change="setChangesMade"
                    class="form-control"
                  >
                    <option
                      v-for="p in displayedServiceTypes"
                      :key="p.value"
                      :value="p.value"
                    >
                      {{ p.label }}
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <label>Service</label>
                  <select
                    required
                    v-model="clickedEventDetails_service.id"
                    :disabled="
                      clickedEventDetails_deleted ||
                      !canEnable(clickedEventDetails_provider_user_id) ||
                      !(
                        (clickedEventDetails_status == 'Ready for Review' ||
                          clickedEventDetails_status == 'PENDING') &&
                        user &&
                        (user.isAdmin ||
                          user.isManagingAdmin ||
                          (currentProvider && currentProvider.can_verify_event))
                      )
                    "
                    @change="updateSelectedService($event.target.value)"
                    class="form-control"
                  >
                    <option
                      v-for="p in parentServices"
                      :key="p.id"
                      :value="p.id"
                      :disabled="p.status == 'INACTIVE'"
                    >
                      {{ p.name }} {{ p.catastrophic ? "(CAT)" : "" }}
                    </option>
                  </select>
                  <div
                    class="w-100 text-right"
                    v-if="
                      user &&
                      !user.isPatient &&
                      clickedEventDetails_service.id &&
                      isBillable &&
                      !clickedEventDetails_deleted &&
                      canEnable(clickedEventDetails_provider_user_id)
                    "
                  >
                    <a
                      href="#nav-profile"
                      @click.prevent="selectTab('#nav-profile-tab')"
                      class="h5 mr-3"
                      >Add sub service</a
                    >
                  </div>
                </div>
                <input-control
                  control="number"
                  :disabled="
                    clickedEventDetails_status == 'Verified' ||
                    clickedEventDetails_deleted
                  "
                  v-model="clickedEventDetails_providerRate"
                  @input="setChangesMade"
                  v-if="canEnable(clickedEventDetails_provider_user_id)"
                  type="vertical"
                  step="0.1"
                  min="0"
                  >Provider Rate</input-control
                >
              </div>
              <div
                class="col-12 mb-2"
                v-if="
                  !clickedEventDetails_deleted &&
                  canEnable(clickedEventDetails_provider_user_id)
                "
              >
                <input
                  type="checkbox"
                  id="allowOverlap"
                  v-model="allowOverlap"
                  @change="setChangesMade"
                />
                <label
                  for="allowOverlap"
                  class="pointer-cursor ml-2 text-dark-red"
                  >Allow Overlapping With Other Sessions</label
                >
              </div>
            </div>
          </div>

          <div
            class="tab-pane fade"
            id="nav-profile"
            role="tabpanel"
            aria-labelledby="nav-profile-tab"
          >
            <label class="serviceName"
              >{{
                clickedEventDetails_mainService != null
                  ? clickedEventDetails_mainService.name
                  : ""
              }}
            </label>
            <div
              v-if="
                clickedEventDetails_services != null &&
                clickedEventDetails_services.length != 0
              "
            >
              <span style="color: #f00; font-size: 12px"
                >* Only the selected Sub Service will be submitted</span
              >

              <div
                class="subServiceItem col-md-12"
                v-for="subService in clickedEventDetails_services"
                :key="subService.id"
              >
                <div class="row mb-2 align-items-center">
                  <input
                    class="form-control pointer-cursor checkbox-input"
                    type="checkbox"
                    :id="`${subService.name}_${subService.id}`"
                    v-model="subService.selected"
                    @change="setChangesMade"
                    :disabled="
                      clickedEventDetails_deleted ||
                      !canEnable(clickedEventDetails_provider_user_id) ||
                      subService.is_default == true ||
                      clickedEventDetails_status == 'Verified' ||
                      (subService.status == 'INACTIVE' && !subService.selected)
                    "
                  />
                  <label
                    :for="`${subService.name}_${subService.id}`"
                    class="pointer-cursor ml-2 mb-0"
                    >Selected <span style="color: #f00">*</span></label
                  >
                  <span
                    v-if="
                      subService.status == 'INACTIVE' && !subService.selected
                    "
                    class="font-weight-bold text-danger float-right"
                    v-tooltip.top="
                      'The sub service has no longer been active and can\'t be used anymore'
                    "
                    >INACTIVE</span
                  >
                </div>
                <div
                  class="subServiceItemContainer"
                  :class="
                    subService.something == false ? 'disabledSubService1' : ''
                  "
                >
                  <h3 class="SubServiceTitle">{{ subService.name }}</h3>
                  <div class="row">
                    <div
                      class="col-md-4"
                      v-if="subService.pivot && user && !user.isProvider"
                    >
                      <input-control
                        :disabled="
                          clickedEventDetails_deleted ||
                          !canEnable(clickedEventDetails_provider_user_id) ||
                          (user && user.isProvider) ||
                          clickedEventDetails_status == 'Verified' ||
                          (subService.status == 'INACTIVE' &&
                            !subService.selected) ||
                          true
                        "
                        type="vertical"
                        v-model="subService.pivot.rate"
                        @change="setChangesMade"
                        @input="setChangesMade"
                        control="number"
                        step="0.01"
                        >Rate</input-control
                      >
                    </div>

                    <div
                      class="col-md-4"
                      v-if="!subService.pivot && user && !user.isProvider"
                    >
                      <input-control
                        type="vertical"
                        :disabled="
                          clickedEventDetails_deleted ||
                          !canEnable(clickedEventDetails_provider_user_id) ||
                          (user && user.isProvider) ||
                          clickedEventDetails_status == 'Verified' ||
                          (subService.status == 'INACTIVE' &&
                            !subService.selected) ||
                          true
                        "
                        v-model="subService.rate"
                        @change="setChangesMade"
                        @input="setChangesMade"
                        control="number"
                        step="0.01"
                        >Rate</input-control
                      >
                    </div>

                    <div
                      class="mt-3 mt-md-0"
                      :class="`${
                        user && user.isProvider ? 'col-md-6' : 'col-md-4'
                      }`"
                      v-if="subService.pivot"
                    >
                      <input-control
                        type="vertical"
                        v-model="subService.pivot.quantity"
                        :disabled="
                          clickedEventDetails_deleted ||
                          !canEnable(clickedEventDetails_provider_user_id) ||
                          clickedEventDetails_status == 'Verified' ||
                          (subService.status == 'INACTIVE' &&
                            !subService.selected) ||
                          subService.unit == 'FT' ||
                          subService.unit == 'FLAT'
                        "
                        @change="setChangesMade"
                        @input="setChangesMade"
                        :required="subService.selected"
                        control="number"
                        step="0.01"
                        >Quantity</input-control
                      >
                    </div>

                    <div
                      class="mt-3 mt-md-0"
                      :class="`${
                        user && user.isProvider ? 'col-md-6' : 'col-md-4'
                      }`"
                      v-else
                    >
                      <input-control
                        type="vertical"
                        v-model="subService.qty"
                        @change="setChangesMade"
                        @input="setChangesMade"
                        :required="subService.selected"
                        :disabled="
                          clickedEventDetails_deleted ||
                          !canEnable(clickedEventDetails_provider_user_id) ||
                          clickedEventDetails_status == 'Verified' ||
                          (subService.status == 'INACTIVE' &&
                            !subService.selected) ||
                          subService.unit == 'FT' ||
                          subService.unit == 'FLAT'
                        "
                        control="number"
                        step="0.01"
                        >Quantity</input-control
                      >
                    </div>

                    <div
                      class="mt-3 mt-md-0"
                      :class="`${
                        user && user.isProvider ? 'col-md-6' : 'col-md-4'
                      }`"
                      v-if="subService.pivot"
                    >
                      <select-control
                        type="vertical"
                        v-model="subService.pivot.unit"
                        @change="setChangesMade"
                        @input="setChangesMade"
                        labelClass="mr-0"
                        disabled
                        :options="units"
                        >Unit</select-control
                      >
                    </div>

                    <div
                      class="mt-3 mt-md-0"
                      :class="`${
                        user && user.isProvider ? 'col-md-6' : 'col-md-4'
                      }`"
                      v-else
                    >
                      <select-control
                        type="vertical"
                        v-model="subService.unit"
                        disabled
                        @change="setChangesMade"
                        @input="setChangesMade"
                        labelClass="mr-0"
                        :options="units"
                        >Unit</select-control
                      >
                    </div>
                  </div>

                  <div class="row mt-3" v-if="subService.pivot">
                    <div class="col-md-6" v-if="false">
                      <div class="row">
                        <label class="col-md-12">Taxable</label>
                      </div>
                      <input
                        type="radio"
                        id="yes"
                        value="1"
                        :disabled="
                          clickedEventDetails_deleted ||
                          !canEnable(clickedEventDetails_provider_user_id) ||
                          clickedEventDetails_status == 'Verified' ||
                          (subService.status == 'INACTIVE' &&
                            !subService.selected)
                        "
                        v-model="subService.pivot.taxable"
                        @change="setChangesMade"
                        @input="setChangesMade"
                      />
                      <label for="yes">Yes</label>
                      <input
                        type="radio"
                        id="no"
                        value="0"
                        :disabled="
                          clickedEventDetails_deleted ||
                          !canEnable(clickedEventDetails_provider_user_id) ||
                          clickedEventDetails_status == 'Verified' ||
                          (subService.status == 'INACTIVE' &&
                            !subService.selected)
                        "
                        v-model="subService.pivot.taxable"
                        @change="setChangesMade"
                        @input="setChangesMade"
                      />
                      <label for="no">No</label>
                    </div>
                  </div>

                  <div class="row mt-3" v-else>
                    <div class="col-md-6" v-if="false">
                      <div class="row">
                        <label class="col-md-12">Taxable</label>
                      </div>
                      <input
                        type="radio"
                        id="yes"
                        value="true"
                        :disabled="
                          clickedEventDetails_deleted ||
                          !canEnable(clickedEventDetails_provider_user_id) ||
                          clickedEventDetails_status == 'Verified' ||
                          (subService.status == 'INACTIVE' &&
                            !subService.selected)
                        "
                        v-model="subService.taxable"
                        @change="setChangesMade"
                        @input="setChangesMade"
                      />
                      <label for="yes">Yes</label>
                      <input
                        type="radio"
                        id="no"
                        value="false"
                        :disabled="
                          clickedEventDetails_deleted ||
                          !canEnable(clickedEventDetails_provider_user_id) ||
                          clickedEventDetails_status == 'Verified' ||
                          (subService.status == 'INACTIVE' &&
                            !subService.selected)
                        "
                        v-model="subService.taxable"
                        @change="setChangesMade"
                        @input="setChangesMade"
                      />
                      <label for="no">No</label>
                    </div>
                  </div>

                  <div class="row mt-3" v-if="subService.pivot">
                    <div class="col-md-12">
                      <input-control
                        type="vertical"
                        rows="6"
                        :disabled="
                          clickedEventDetails_deleted ||
                          !canEnable(clickedEventDetails_provider_user_id) ||
                          clickedEventDetails_status == 'Verified' ||
                          (subService.status == 'INACTIVE' &&
                            !subService.selected)
                        "
                        v-model="subService.pivot.description"
                        @change="setChangesMade"
                        @input="setChangesMade"
                        maxLength="500"
                        control="textarea"
                        >Description
                      </input-control>
                    </div>
                  </div>

                  <div class="row mt-3" v-else>
                    <div class="col-md-12">
                      <input-control
                        type="vertical"
                        rows="6"
                        v-model="subService.description"
                        :disabled="
                          clickedEventDetails_deleted ||
                          !canEnable(clickedEventDetails_provider_user_id) ||
                          clickedEventDetails_status == 'Verified' ||
                          (subService.status == 'INACTIVE' &&
                            !subService.selected)
                        "
                        @change="setChangesMade"
                        @input="setChangesMade"
                        maxLength="500"
                        control="textarea"
                        >Description
                      </input-control>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-100 text-center mb-5">
              <a
                class="btn btn-success"
                href="#nav-home"
                @click.prevent="selectTab('#nav-home-tab')"
                >Back To Details</a
              >
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="nav-memo"
            role="tabpanel"
            aria-labelledby="nav-memo-tab"
          >
            <div class="form-group">
              <label
                >Reason{{
                  user && !user.isPatient ? " - Seen by client" : ""
                }}</label
              >
              <textarea
                :disabled="
                  (user && user.isPatient) ||
                  clickedEventDetails_deleted ||
                  clickedEventDetails_status == 'Verified' ||
                  !canEnable(clickedEventDetails_provider_user_id)
                "
                class="form-control"
                v-model="clickedEventDetails_reason"
                @change="setChangesMade"
                rows="5"
              ></textarea>
            </div>
            <div class="form-group" v-if="user && !user.isPatient">
              <label>Memo</label>
              <textarea
                class="form-control"
                :disabled="
                  clickedEventDetails_status == 'Verified' ||
                  clickedEventDetails_deleted ||
                  !canEnable(clickedEventDetails_provider_user_id)
                "
                v-model="clickedEventDetails_memo"
                @change="setChangesMade"
                rows="5"
              ></textarea>
            </div>
            <div class="w-100 text-center mb-5">
              <a
                class="btn btn-success"
                href="#nav-home"
                @click.prevent="selectTab('#nav-home-tab')"
                >Back To Details</a
              >
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="nav-expenses"
            role="tabpanel"
            aria-labelledby="nav-expenses-tab"
          >
            <div class="form-group">
              <input-control
                control="number"
                :disabled="
                  clickedEventDetails_status == 'Verified' ||
                  clickedEventDetails_deleted ||
                  !canEnable(clickedEventDetails_provider_user_id)
                "
                v-model="clickedEventDetails_expenses"
                @input="setChangesMade"
                type="vertical"
                step="0.01"
                min="0"
                >Expenses $</input-control
              >
              <input-control
                type="vertical"
                rows="6"
                v-model="clickedEventDetails_billing_details"
                @change="setChangesMade"
                :disabled="
                  clickedEventDetails_status == 'Verified' ||
                  clickedEventDetails_deleted ||
                  !canEnable(clickedEventDetails_provider_user_id)
                "
                @input="setChangesMade"
                control="textarea"
                >Billing Details
              </input-control>
            </div>
            <div class="w-100 text-center mb-5">
              <a
                class="btn btn-success"
                href="#nav-home"
                @click.prevent="selectTab('#nav-home-tab')"
                >Back To Details</a
              >
            </div>
          </div>
        </div>
        <div class="col p-0 mb-4" v-if="user && !user.isPatient">
          <div
            v-if="
              clickedEventDetails_createdBy &&
              clickedEventDetails_createdAt &&
              clickedEventDetails_createdBy != '-' &&
              clickedEventDetails_createdAt != '-'
            "
            class="mb-2"
          >
            Created by
            <b class="text-danger">{{ clickedEventDetails_createdBy }}</b> at
            <span class="text-secondary">{{
              clickedEventDetails_createdAt
            }}</span>
          </div>
          <div
            v-if="
              clickedEventDetails_verifiedBy &&
              clickedEventDetails_verifiedAt &&
              clickedEventDetails_verifiedBy != '-' &&
              clickedEventDetails_verifiedAt != '-'
            "
          >
            {{
              clickedEventDetails_status == "Verified"
                ? "Verified"
                : "Unverified"
            }}
            by
            <b class="text-danger">{{ clickedEventDetails_verifiedBy }}</b> at
            <span class="text-secondary">{{
              clickedEventDetails_verifiedAt
            }}</span>
          </div>
          <div v-if="clickedEventDetails_deleted" class="mt-2">
            Removed by
            <b class="text-danger">{{ clickedEventDetails_deletedBy }}</b> at
            <span class="text-secondary">{{
              clickedEventDetails_deletedAt
            }}</span>
          </div>
        </div>
        <div class="row m-0 d-flex justify-content-center">
          <div
            v-if="
              user &&
              !user.isPatient &&
              !clickedEventDetails_deleted &&
              canEnable(clickedEventDetails_provider_user_id)
            "
          >
            <button
              class="btn btn-info"
              @click="UpdateBook()"
              v-if="clickedEventDetails_status != 'Verified'"
            >
              Save Changes
            </button>
          </div>
          <div
            v-if="
              !clickedEventDetails_deleted &&
              canEnable(clickedEventDetails_provider_user_id) &&
              (clickedEventDetails_status == 'Ready for Review' ||
                clickedEventDetails_status == 'PENDING')
            "
          >
            <a
              class="btn btn-info mb-2 ml-3"
              v-if="
                user &&
                (user.isAdmin ||
                  user.isManagingAdmin ||
                  (currentProvider && currentProvider.can_verify_event))
              "
              @click="approveEvent"
            >
              <span
                class="spinner-border spinner-border-sm"
                v-if="loading"
              ></span>
              Verify
            </a>
          </div>
        </div>
      </div>
    </sidebar>

    <modal
      class="clone-session-modal"
      name="clone-session-modal"
      transition="pop-out"
      :height="550"
    >
      <div class="position-relative">
        <div class="vm--top-right-slot" style="z-index: 1">
          <button
            class="btn p-0 pr-3 pt-3 border-0"
            @click="closeCloneSessionModal"
          >
            <i class="fas fa-times fa-lg"></i>
          </button>
        </div>
        <div class="card-body" v-if="eventToClone">
          <div class="row border-bottom h-406 relative-position">
            <label class="col-2 font-weight-bold pt-2">Date</label>
            <div class="mt-2 pt-1 col-10">
              <div class="custom-control custom-checkbox pb-4">
                <input
                  type="radio"
                  name="cloneDateType"
                  id="date-range-enabled"
                  value="dateRange"
                  class="custom-control-input"
                  v-model="cloneFilter.dateRangeOption"
                />
                <label
                  class="custom-control-label mr-3"
                  for="date-range-enabled"
                  >By Date Range</label
                >
              </div>
              <div
                class="col-12 mb-5 p-0"
                v-if="cloneFilter.dateRangeOption == 'dateRange'"
              >
                <div class="d-flex justify-content-between">
                  <datetime
                    v-model="cloneFilter.dateRangeData.startDate"
                    :min-datetime="cloneFilter.minDate"
                    placeholder="End Date"
                    class="col-6"
                    :week-start="7"
                    input-class="col-md-12 form-control"
                    input-style="font-size: 12px;"
                    type="date"
                  >
                  </datetime>
                  <datetime
                    v-model="cloneFilter.dateRangeData.endDate"
                    :min-datetime="cloneFilter.dateRangeData.startDate"
                    :week-start="7"
                    class="col-6"
                    placeholder="End Date"
                    input-class="col-md-12 form-control"
                    input-style="font-size: 12px;"
                    type="date"
                  >
                  </datetime>
                </div>
                <div class="radio-btn-group mt-4 pl-3">
                  <div class="radio">
                    <input
                      type="radio"
                      name="weekDay"
                      id="sunday"
                      value="0"
                      v-model="cloneFilter.dateRangeData.weekDay"
                    />
                    <label for="sunday">Sun</label>
                  </div>
                  <div class="radio">
                    <input
                      type="radio"
                      name="weekDay"
                      id="monday"
                      value="1"
                      v-model="cloneFilter.dateRangeData.weekDay"
                    />
                    <label for="monday">Mon</label>
                  </div>
                  <div class="radio">
                    <input
                      type="radio"
                      name="weekDay"
                      id="tuesday"
                      value="2"
                      v-model="cloneFilter.dateRangeData.weekDay"
                    />
                    <label for="tuesday">Tue</label>
                  </div>
                  <div class="radio">
                    <input
                      type="radio"
                      name="weekDay"
                      id="wednesday"
                      value="3"
                      v-model="cloneFilter.dateRangeData.weekDay"
                    />
                    <label for="wednesday">Wed</label>
                  </div>
                  <div class="radio">
                    <input
                      type="radio"
                      name="weekDay"
                      id="thursday"
                      value="4"
                      v-model="cloneFilter.dateRangeData.weekDay"
                    />
                    <label for="thursday">Thu</label>
                  </div>
                  <div class="radio">
                    <input
                      type="radio"
                      name="weekDay"
                      id="friday"
                      value="5"
                      v-model="cloneFilter.dateRangeData.weekDay"
                    />
                    <label for="friday">Fri</label>
                  </div>
                  <div class="radio">
                    <input
                      type="radio"
                      name="weekDay"
                      id="saturday"
                      value="6"
                      v-model="cloneFilter.dateRangeData.weekDay"
                    />
                    <label for="saturday">Sat</label>
                  </div>
                </div>
              </div>
              <div class="custom-control custom-checkbox pb-4">
                <input
                  type="radio"
                  name="cloneDateType"
                  id="custom-dates-enabled"
                  value="customDates"
                  class="custom-control-input"
                  v-model="cloneFilter.dateRangeOption"
                />
                <label
                  class="custom-control-label mr-3"
                  for="custom-dates-enabled"
                  >By Custom Dates</label
                >
              </div>
              <div
                class="
                  d-flex
                  justify-content-between
                  col-12
                  mb-5
                  py-0
                  px-4
                  custom-date-mobile
                "
                v-if="cloneFilter.dateRangeOption == 'customDates'"
              >
                <v-calendar
                  :attributes="attributes"
                  @dayclick="onDayClick"
                  :min-date="cloneFilter.minDate"
                  is-expanded
                />
              </div>
            </div>
            <div class="col-12 absolute-position bottom-0">
              <input
                type="checkbox"
                id="allowOverlapping"
                v-model="allowOverlap"
              />
              <label
                for="allowOverlapping"
                class="pointer-cursor ml-2 mb-0 text-dark-red"
                >Allow Overlapping With Other Sessions</label
              >
            </div>
          </div>
          <div class="saving-note-container" v-if="cloneFilter.isSaving">
            <div
              class="saving-note alert alert-info m-0 mb-1 py-1"
              role="alert"
            >
              This may take few moments...
            </div>
          </div>

          <div class="my-4 d-flex justify-content-center">
            <save
              :saving="cloneFilter.isSaving"
              @click="sendCloneRequest"
              savingText="Clone Appointment"
              >Clone Appointment</save
            >
            <div class="btn-group">
              <button
                type="button"
                class="btn px-1 py-0 rounded"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="ml-2 fas fa-cog"></i>
              </button>
              <div
                class="dropdown-menu print-menu right-0 left-auto"
                @click="$event.stopPropagation()"
              >
                <h3 class="mx-3 my-2">
                  <i class="fas fa-cog mr-2"></i>Clone Settings
                </h3>
                <div class="dropdown-divider"></div>
                <div class="dropdown-item">
                  <div class="custom-control custom-switch">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="cloneSettings_expenses"
                      v-model="cloneSettings.expenses"
                    />
                    <label
                      class="custom-control-label"
                      for="cloneSettings_expenses"
                      >Expenses</label
                    >
                  </div>
                </div>
                <div class="dropdown-item">
                  <div class="custom-control custom-switch">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="cloneSettings_billingDetails"
                      v-model="cloneSettings.billingDetails"
                    />
                    <label
                      class="custom-control-label"
                      for="cloneSettings_billingDetails"
                      >Billing Details</label
                    >
                  </div>
                </div>
                <div class="dropdown-item">
                  <div class="custom-control custom-switch">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="cloneSettings_reason"
                      v-model="cloneSettings.reason"
                    />
                    <label
                      class="custom-control-label"
                      for="cloneSettings_reason"
                      >Reason</label
                    >
                  </div>
                </div>
                <div class="dropdown-item">
                  <div class="custom-control custom-switch">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="cloneSettings_memo"
                      v-model="cloneSettings.memo"
                    />
                    <label class="custom-control-label" for="cloneSettings_memo"
                      >Memo</label
                    >
                  </div>
                </div>
                <div class="dropdown-item">
                  <div class="custom-control custom-switch">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="cloneSettings_subServices"
                      v-model="cloneSettings.subServices"
                    />
                    <label
                      class="custom-control-label"
                      for="cloneSettings_subServices"
                      >Sub Services</label
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      class="bulk-sessions-modal"
      name="bulk-sessions-modal"
      transition="pop-out"
      :clickToClose="false"
      height="auto"
    >
      <div
        class="position-relative main-modal-container"
        ref="draggableContainer"
        @mousedown="dragMouseDown"
      >
        <div class="vm--top-right-slot" style="z-index: 1">
          <h2
            class="
              position-absolute
              m-3
              pt-1
              mr-5
              text-left
              font-weight-bold
              pointer-none
            "
            style="letter-spacing: 0.1px"
          >
            Which appointment are you going to verify/unverify?
          </h2>
          <button
            class="btn p-0 pr-3 pt-3 border-0"
            :disabled="
              bulkSessionsFilter.isVerifying || bulkSessionsFilter.isUnverifying
            "
            @click="closeBulkSessionModal"
          >
            <i class="fas fa-times fa-lg"></i>
          </button>
        </div>
        <div class="card-body mt-4">
          <div class="row border-bottom pb-4">
            <div
              class="col-12 col-sm-6 mb-3"
              v-if="user && !user.isPatient && false"
            >
              <select
                v-model="bulkSessionsFilter.classification"
                class="form-control shadow-card"
              >
                <option value="" selected>Filter by Class</option>

                <option v-for="p in classes" :key="p.id" :value="p.id">
                  {{ p.label }}
                </option>
              </select>
            </div>

            <div class="col-12 col-sm-6 mb-3" v-if="user && !user.isPatient">
              <select
                v-model="bulkSessionsFilter.service"
                class="form-control shadow-card"
              >
                <option value="" selected>Filter by Service</option>

                <option
                  v-for="service in parentServicesTemp"
                  :key="service.id"
                  :value="service.id"
                >
                  {{ service.name }}
                </option>
              </select>
            </div>

            <div class="col-12 col-sm-6 mb-3">
              <select
                v-if="!user || !user.isPatient"
                v-model="bulkSessionsFilter.Patient"
                class="form-control shadow-card"
              >
                <option value="" selected>
                  Filter by Specific User/Client
                </option>
                <optgroup
                  label="Admins"
                  v-if="
                    user &&
                    (user.isAdmin || user.isManagingAdmin) &&
                    adminUsers.length
                  "
                >
                  <option v-for="p in adminUsers" :key="p.id" :value="p.id">
                    {{ p.last }}, {{ p.first }}
                  </option>
                </optgroup>
                <optgroup
                  label="Providers"
                  v-if="providerUsers.length && user && !user.isProvider"
                >
                  <option
                    v-for="p in providerUsers"
                    :key="p.id"
                    :value="p.user_id || p.id"
                  >
                    {{ p.last }}, {{ p.first }} {{ p.user_id }}
                  </option>
                </optgroup>
                <optgroup label="Clients" v-if="patientUsers.length">
                  <option
                    v-for="p in patientUsers"
                    :key="p.user_id || p.id"
                    :value="p.user_id || p.id"
                  >
                    {{ p.last }}, {{ p.first }}
                  </option>
                </optgroup>
              </select>
              <select
                v-if="user && user.isPatient"
                v-model="bulkSessionsFilter.Provider"
                class="form-control shadow-card"
              >
                <option value="" selected>Filter by Specific Provider</option>
                <option
                  v-for="p in providerUsers"
                  :key="p.id"
                  :value="p.user_id || p.id"
                >
                  {{ p.last }}, {{ p.first }} {{ p.user_id }}
                </option>
              </select>
            </div>

            <div
              class="col-12 col-sm-6 mb-3"
              v-if="user && (user.isAdmin || user.isManagingAdmin)"
            >
              <select
                v-model="bulkSessionsFilter.Provider"
                class="form-control shadow-card"
              >
                <option value="" selected>
                  Filter by Specific Provider/Admin
                </option>

                <optgroup
                  label="Admins"
                  v-if="
                    user &&
                    (user.isAdmin || user.isManagingAdmin) &&
                    adminUsers.length
                  "
                >
                  <option v-for="p in adminUsers" :key="p.id" :value="p.id">
                    {{ p.last }}, {{ p.first }}
                  </option>
                </optgroup>
                <optgroup label="Providers" v-if="providerUsers.length">
                  <option v-for="p in providerUsers" :key="p.id" :value="p.id">
                    {{ p.last }}, {{ p.first }}
                  </option>
                </optgroup>
              </select>
            </div>

            <div class="col-12 col-sm-6 mb-3" v-if="user && !user.isPatient">
              <select
                v-model="bulkSessionsFilter.billableStatus"
                class="form-control shadow-card"
              >
                <option value="" selected>Filter by Billable Status</option>

                <option v-for="p in billableStatuses" :key="p.id" :value="p.id">
                  {{ p.label }}
                </option>
              </select>
            </div>

            <div class="d-flex col-12 mb-3 mt-2">
              <div class="custom-control custom-checkbox">
                <input
                  type="radio"
                  name="bulkDateType"
                  id="bulk-date-range-enabled"
                  value="dateRange"
                  class="custom-control-input"
                  v-model="bulkSessionsFilter.dateRangeOption"
                />
                <label
                  class="custom-control-label mr-3 font-weight-bold"
                  for="bulk-date-range-enabled"
                  ><span class="pointer-none">Date Range</span></label
                >
              </div>
              <div class="custom-control custom-checkbox ml-3">
                <input
                  type="radio"
                  name="bulkDateType"
                  id="bulk-date-custom-enabled"
                  value="customDates"
                  class="custom-control-input"
                  v-model="bulkSessionsFilter.dateRangeOption"
                />
                <label
                  class="custom-control-label font-weight-bold"
                  for="bulk-date-custom-enabled"
                  ><span class="pointer-none">Custom Dates</span></label
                >
              </div>
            </div>
            <div
              class="row m-0 col-12 px-0 align-items-center"
              v-if="bulkSessionsFilter.dateRangeOption == 'dateRange'"
            >
              <div class="col-12">
                <v-date-picker
                  v-model="bulkSessionsFilter.range"
                  mode="date"
                  :masks="{ input: 'YYYY-MM-DD' }"
                  is-range
                >
                  <template v-slot="{ inputValue, inputEvents, isDragging }">
                    <div
                      class="
                        d-flex
                        justify-content-start
                        align-items-center
                        form-group
                        mb-0
                      "
                    >
                      <input
                        class="px-2 py-1 form-control shadow-card"
                        :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                        :value="inputValue.start"
                        v-on="inputEvents.start"
                      />
                      <div
                        style="width: 24px; min-width: 24px"
                        class="text-center font-weight-bold"
                      >
                        -
                      </div>
                      <input
                        class="px-2 py-1 form-control shadow-card"
                        :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                        :value="inputValue.end"
                        v-on="inputEvents.end"
                      />
                    </div>
                  </template>
                </v-date-picker>
              </div>
            </div>
            <div
              class="
                d-flex
                justify-content-between
                col-12 col-sm-8
                ml-auto
                mr-auto
                py-0
              "
              v-if="bulkSessionsFilter.dateRangeOption == 'customDates'"
            >
              <v-calendar
                :attributes="bulkDateAttributes"
                @dayclick="onBulkDayClick"
                is-expanded
              />
            </div>
          </div>
          <div
            class="saving-note-container"
            v-if="
              bulkSessionsFilter.isVerifying || bulkSessionsFilter.isUnverifying
            "
          >
            <div
              class="saving-note alert alert-info m-0 mb-1 py-1"
              role="alert"
            >
              This may take few moments...
            </div>
          </div>

          <div class="mb-2 mt-5 d-flex justify-content-center">
            <save
              :saving="bulkSessionsFilter.isUnverifying"
              :shouldDisable="bulkSessionsFilter.isVerifying"
              iconType="times-circle"
              @click="verifyUnverifyBulkSessions()"
              classes="btn btn-danger mr-4"
              savingText="Unverifying Appointments"
              >Unverify Appointments</save
            >
            <save
              :saving="bulkSessionsFilter.isVerifying"
              :shouldDisable="bulkSessionsFilter.isUnverifying"
              @click="verifyUnverifyBulkSessions(true)"
              savingText="Verifying Appointments"
              >Verify Appointments</save
            >
          </div>
        </div>
      </div>
    </modal>

    <modal
      class="availability-session-modal"
      name="availability-session-modal"
      transition="pop-out"
      width="750"
      height="96%"
      :resizable="true"
      :adaptive="true"
    >
      <div
        class="position-relative h-100"
        ref="draggableAvailabilityContainer"
        @mousedown="dragAvailabilityMouseDown"
      >
        <div class="vm--top-right-slot" style="z-index: 1">
          <button
            class="btn p-0 pr-3 pt-3 border-0"
            @click="closeUserAvailabilityModal"
          >
            <i class="fas fa-times fa-lg"></i>
          </button>
        </div>
        <div class="card-body" v-if="availabilityUserId">
          <availability :current-user-id="availabilityUserId" :noEdit="true" />
        </div>
      </div>
    </modal>

    <div class="calendar-print-footer pt-2">
      <div class="row m-0">
        <div class="col-6">
          <p
            class="mb-0"
            v-if="
              companyProfile &&
              companyProfile.show_name_in_report &&
              companyProfile.name
            "
          >
            {{ companyProfile.name }}
          </p>
          <p
            class="mb-0"
            v-if="
              companyProfile &&
              companyProfile.show_email_in_report &&
              companyProfile.email
            "
          >
            {{ companyProfile.email }}
          </p>
        </div>
        <div class="col-6 text-right">
          <p
            class="mb-0"
            v-if="
              companyProfile &&
              companyProfile.show_phone_number_in_report &&
              companyProfile.phone_number
            "
          >
            {{ companyProfile.phone_number }}
          </p>
        </div>
        <div class="col-12">
          <p
            class="mb-0"
            v-if="
              companyProfile &&
              companyProfile.show_bio_in_report &&
              companyProfile.bio
            "
          >
            {{ companyProfile.bio }}
          </p>
          <p
            class="mb-0 text-center"
            v-if="
              companyProfile &&
              companyProfile.show_url_in_report &&
              companyProfile.url
            "
          >
            {{ companyProfile.url }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<!-- appointment Update End -->

<script>
import FullCalendar from "@fullcalendar/vue";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";
import bootstrap from "@fullcalendar/bootstrap";
import dateFormat from "@/utils/dateFormat";
import { mapActions, mapState } from "vuex";
import Swal from "sweetalert2";
import * as dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { orderBy } from "lodash";
import Availability from "../../components/Availability.vue";

import { atcb_init, atcb_action } from "add-to-calendar-button";
import "add-to-calendar-button/assets/css/atcb.css";

dayjs.extend(utc);
dayjs.extend(timezone);

import { Datetime } from "vue-datetime";
import Loader from "../../components/Loader.vue";
let calendarApi = null;
export default {
  name: "Index",
  components: {
    FullCalendar,
    datetime: Datetime,
    Loader,
    Availability,
  },
  props: [
    "profileProviderUserId",
    "profilePatientUserId",
    "profileAdminUserId",
  ],
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      patients: (s) => s.patients.calendarPatients,
      providers: (s) => s.providers.calendarProviders,
      users: (state) => state.security.calendarUsers,
      userInfo: (state) => state.auth.userInfo,
      patientProviders: (state) => state.patients.providers,
      companyProfile: (state) => state.settings.companyProfile,
      patientData: (state) => state.patients.patient,
      classifications: (state) => state.classifications.data,
    }),
    calendarTitle() {
      if (this.events && calendarApi) {
        return calendarApi.currentData.viewTitle || "";
      }
      return "";
    },
    attributes() {
      return this.cloneFilter.customDates.map((date) => ({
        highlight: true,
        dates: date,
      }));
    },
    bulkDateAttributes() {
      return this.bulkSessionsFilter.customDates.map((date) => ({
        highlight: true,
        dates: date,
      }));
    },
    selectedMainServiceOnCreate() {
      if (this.booking.primary) {
        return this.parentServices.filter(
          (serv) => serv.id == this.booking.primary
        )[0];
      }
      return {};
    },
    currentTimeZone() {
      return dayjs.tz.guess();
    },
    adminUsers() {
      return this.sortAlphabetically(
        this.users.filter((user) => user.isAdmin || user.isManagingAdmin)
      );
    },
    providerUsers() {
      if ((this.user || {}).isPatient) {
        const providersIds = (this.patientProviders.data || []).map((item) =>
          parseInt(item.user_id)
        );
        return this.sortAlphabetically(
          this.users.filter(
            (user) => user.isProvider && providersIds.includes(user.id)
          )
        );
      }
      return this.sortAlphabetically(
        this.users.filter((user) => user.isProvider)
      );
    },
    patientUsers() {
      if ((this.user || {}).isProvider) {
        return this.sortAlphabetically(this.patients);
      } else if ((this.user || {}).isPatient) {
        return [this.user];
      }
      return this.sortAlphabetically(
        this.users.filter((user) => user.isPatient)
      );
    },
    displayedProviders() {
      if (this.isBillable) {
        return this.sortAlphabetically(this.providers);
      }
      return this.sortAlphabetically(
        this.users.filter((user) => !user.isPatient)
      );
    },
    displayedPatients() {
      if (this.isBillable) {
        if (this.openTray) {
          if (
            (this.booking.provider || "").toString().includes("_") &&
            `${this.booking.provider}`.split("_")[1] == "true"
          ) {
            return this.sortAlphabetically(this.patients);
          }
          const selectedProvider = this.providers.filter(
            (item) => item.user_id == `${this.booking.provider}`.split("_")[0]
          )[0];
          if (selectedProvider) {
            return this.sortAlphabetically(
              this.patients.filter((patient) =>
                (selectedProvider.patients || []).includes(patient.id)
              )
            );
          }
        } else {
          const selectedProvider = this.providers.filter(
            (item) => item.user_id == this.clickedEventDetails_provider_user_id
          )[0];
          if (selectedProvider) {
            if (this.clickedEventDetails_patient_user_id) {
              const patientId = (
                this.patients.filter(
                  (patient) =>
                    patient.user_id == this.clickedEventDetails_patient_user_id
                )[0] || {}
              ).id;
              const providerPatients = selectedProvider.patients || [];
              if (patientId && !providerPatients.includes(patientId)) {
                providerPatients.push(patientId);
              }
              return this.sortAlphabetically(
                this.patients.filter((patient) =>
                  providerPatients.includes(patient.id)
                )
              );
            } else {
              return this.sortAlphabetically(
                this.patients.filter((patient) =>
                  (selectedProvider.patients || []).includes(patient.id)
                )
              );
            }
          }
        }
        return this.sortAlphabetically(this.patients);
      }
      return this.sortAlphabetically(this.users);
    },
    displayedServiceTypes() {
      return this.ServiceTypes;
    },
    classes() {
      if (this.classifications && this.classifications.length) {
        return this.classifications.map((item) => {
          return { label: item.subject, id: item.id };
        });
      }
      return [];
    },
    paramAdminUserId: function () {
      return this.$route.query.admin;
    },
  },
  data() {
    return {
      sentAptRequests: [],
      hideDeleted: true,
      assignedToMe: false,
      selectedProviderIsAdminType: false,
      isBillable: true,
      isHidden: false,
      changesMade: false,
      gettingUsers: false,
      mainNonBillableService: null,
      nonBillableServices: [],
      showAutoScheduleOption: false,
      verifyBookEvent: false,
      allowOverlap: false,
      actionInProgress: false,
      loading: false,
      currentProvider: undefined,
      eventModified: false,
      eventToClone: undefined,
      eventToHide: undefined,
      totalBillable: 0,
      totalNonBillable: 0,
      subServicesQty: "",
      parentServices: [],
      eventsToPrint: [],
      parentServicesTemp: [],
      cloneSettings: {
        expenses: false,
        billingDetails: true,
        memo: true,
        reason: true,
        subServices: true,
      },
      filter: {
        AppStatus: "",
        billableStatus: "",
        classification: "",
        Patient: "",
        Provider: "",
      },
      bulkSessionsFilter: {
        AppStatus: "",
        billableStatus: "",
        classification: "",
        service: "",
        Patient: "",
        Provider: "",
        isVerifying: false,
        isUnverifying: false,
        dateRangeOption: "dateRange",
        range: {
          start: new Date(),
          end: new Date(),
        },
        customDates: [],
        customDatesIds: [],
      },
      cloneFilter: {
        dateRangeOption: "dateRange",
        isSaving: false,
        dateRangeData: {
          weekDay: 0,
          startDate: "",
          endDate: "",
        },
        customDates: [],
        customDatesIds: [],
      },
      appStatuses: [
        { label: "Pending", id: "PENDING" },
        { label: "Ready For Review", id: "Ready for Review" },
        { label: "Verified", id: "Verified" },
      ],
      billableStatuses: [
        { label: "Billable", id: "billable" },
        { label: "Non Billable", id: "non-billable" },
      ],
      selectedProvider: null,
      selectedPatient: null,
      clickedEventDetails_type: null,
      clickedEventDetails_providerRate: 0,
      clickedEventDetails_id: "",
      clickedEventDetails_startStr: "",
      clickedEventDetails_endStr: "",
      clickedEventDetails_title: "",
      clickedEventDetails_start: null,
      clickedEventDetails_end: null,
      clickedEventDetails_primary: null,
      clickedEventDetails_service: null,
      clickedEventDetails_deleted: false,
      clickedEventDetails_end_time: "",
      clickedEventDetails_start_time: "",
      clickedEventDetails_status: "",
      clickedEventDetails_meetingUrl: "",
      clickedEventDetails_memo: "",
      clickedEventDetails_reason: "",
      clickedEventDetails_expenses: 0,
      clickedEventDetails_billing_details: "",
      clickedEventDetails_patient: null,
      clickedEventDetails_provider: null,
      clickedEventDetails_mainService: null,
      clickedEventDetails_provider_user_id: 0,
      clickedEventDetails_patient_user_id: 0,
      clickedEventDetails_hidden: null,
      clickedEventDetails_services: [],
      clickedEventDetails: {
        id: "",
        title: "",
        start: null,
        end: null,
        primary: null,
        service: null,
        end_time: "",
        start_time: "",
        status: "",
        type: "",
        memo: "",
        provider_rate: 0.0,
        patient: "",
        provider: {
          id: "",
          name: "",
        },
        services: [{}],
        extendedProps: {
          end_time: "",
          start_time: "",
          status: "",
          type: "",
          id: "",
          memo: "",
          patient: {
            id: "",
            name: "",
          },
          provider: {
            id: "",
            name: "",
          },
          services: [
            {
              id: "",
              name: "",
            },
          ],
        },
      },
      openTray: false,
      openUpdateTray: false,
      events: [],
      eventsBackup: [],
      services: [],
      booking: {
        start: null,
        end: null,
        provider: null,
        patient: null,
        primary: null,
        provider_rate: 0.0,
        type: "",
      },
      availabilityUserId: null,
      // Sub Services data
      ServiceTypes: [
        { value: "VIRTUAL", label: "Virtual" },
        { value: "IN-PERSON", label: "In-Person" },
      ],
      units: [
        { value: "HR", label: "Hourly" },
        { value: "KM", label: "Kilometers" },
        { value: "FLAT", label: "Flat Rate" },
        { value: "FT", label: "Flat Rate" },
      ],
      taxableOptions: [
        { label: "Yes", value: "true" },
        { label: "No", value: "false" },
      ],
      catastrphicOptions: [
        { label: "Yes", value: "true" },
        { label: "No", value: "false" },
      ],
      calendar: {
        dayMaxEvents: 1,
        eventDidMount: function (info) {
          if (info.event.extendedProps.status == "Ready for Review") {
            info.backgroundColor = "#0f0";
          }
          if (info.event.extendedProps.status == "PENDING") {
            info.backgroundColor = "#ff0";
          }
          if (info.event.extendedProps.status == "Verified") {
            info.backgroundColor = "#CCC";
          }
        },
        unselectAuto: false,
        businessHours: {
          daysOfWeek: [1, 2, 3, 4, 5],
          start_time: "08:00",
          end_time: "18:00",
        },
        nowIndicator: true,
        selectable: true,
        selectMirror: true,
        headerToolbar: {
          start: "title",
          center: "dayGridMonth,timeGridWeek,timeGridDay",
          end: "prevYear prev today next nextYear",
        },
        slotDuration: "00:15:00",
        scrollTime: "07:00",
        slotLabelInterval: "01:00",
        initialView: "dayGridMonth",
        themeSystem: "bootstrap",
        lazyFetching: false,
        views: {
          dayGridMonth: {
            // name of view
            displayEventEnd: true,
            showNonCurrentDates: false,
            eventContent: function (eventInfo) {
              let subServicesHours = 0;
              const subservices = eventInfo.event.extendedProps.services.filter(
                (serv) => serv.parent_service_id && serv.unit == "HR"
              );
              subservices.forEach((subserv) => {
                subServicesHours += parseFloat(
                  (subserv.pivot || {}).quantity || 0
                );
              });

              const cloneElement = `<i class="far fa-lg fa-clone calendar-clone-icon ml-auto mr-3" onclick='event.stopPropagation(); openCloneSessionModal(${eventInfo.event.id});'></i>`;
              const secretElement = `<i class="fas fa-lg fa-user-secret secret-session" onclick='event.stopPropagation(); updateHiddenValue(${eventInfo.event.id});'></i>`;
              const unsecretElement = `<i class="fas fa-lg  fa-solid fa-solid fa-unlock secret-session" onclick='event.stopPropagation(); updateHiddenValue(${eventInfo.event.id});'></i>`;
              const subMinutes = (
                (subServicesHours - parseInt(subServicesHours)) *
                60
              ).toFixed(0);

              const user_1 =
                eventInfo.event.extendedProps.provider ||
                eventInfo.event.extendedProps.user_1;
              const provider_name = eventInfo.event.extendedProps.providerName;
              const user_1_id = user_1.user_id || user_1.id;
              const htmlContent = `<div class='month-event-container ${
                eventInfo.backgroundColor == "darkgreen"
                  ? " verified-card"
                  : " pending-card"
              } ${
                eventInfo.event.extendedProps.deleted ? "deleted-session" : ""
              } ${
                eventInfo.event.extendedProps.billable
                  ? ""
                  : " non-billable-card"
              }' style='background-color: rgba(${
                ((eventInfo.event.extendedProps.services || [])[0] || {})
                  .color || "204, 204, 204, 1"
              }); background-image: ${
                (eventInfo.event.extendedProps || {}).deleted
                  ? `url(${require("@/assets/img/removed.jpg")})`
                  : ""
              }'>
              ${
                this.user && (this.user.isAdmin || this.user.isManagingAdmin)
                  ? eventInfo.event.extendedProps.hidden
                    ? secretElement
                    : unsecretElement
                  : ""
              }
              <i class="fas fa-lg fa-check-circle verified-mark" style="display: ${
                eventInfo.backgroundColor == "darkgreen" ? "" : "none"
              };"></i>
            <div class="event-service">${
              eventInfo.event.extendedProps.deleted
                ? `<h2 class='text-danger font-weight-bold m-0' style="letter-spacing: 1.2px;">REMOVED</h2>`
                : ((eventInfo.event.extendedProps.services || [])[0] || {})
                    .name || ""
            }</div>
            <div class="d-flex event-time-container">
            <div class="fc-daygrid-event-dot" style="display:inline-block; border-color: ${
              eventInfo.backgroundColor
            };"></div>
            <span class="event-start-end-time">${
              eventInfo.timeText || ""
            }</span>
            <span class="event-time-diff">${
              eventInfo.event.extendedProps.hours
            }h${
                eventInfo.event.extendedProps.minutes > 0
                  ? " " + eventInfo.event.extendedProps.minutes + "m"
                  : ""
              }</span>
              <span class="event-time-diff sub-time-diff pl-3 ${
                subServicesHours > 0 ? "" : "empty"
              }">+ ${parseInt(subServicesHours)}h${
                subMinutes > 0 ? " " + subMinutes + "m" : ""
              }</span>
            ${
              !eventInfo.event.extendedProps.isloggedinPatient &&
              !eventInfo.event.extendedProps.deleted &&
              this.canEnable(user_1_id)
                ? cloneElement
                : ""
            }
            </div>
            <div class="event-user-info">
             <b>P:</b> ${provider_name}
              </div>
            <div class="event-user-info">
              <b>C:</b>
              ${(eventInfo.event || {}).title || ""}
            </div>
            </div>`;
              return { html: htmlContent };
            }.bind(this),
            // other view-specific options here
          },
          timeGridWeek: {
            eventContent: function (eventInfo) {
              const provider_name = eventInfo.event.extendedProps.providerName;
              const client_name = eventInfo.event._def.title;
              window
                .$(".fc-event-main div")
                .css({ "font-size": "15px", "text-shadow": "0 0 4px #ffffff" });
              eventInfo.backgroundColor = `rgba(${
                (
                  ((eventInfo.event.extendedProps || {}).services || [])[0] ||
                  {}
                ).color || "204, 204, 204, 1"
              })`;
              eventInfo.borderColor = `rgba(${
                (
                  ((eventInfo.event.extendedProps || {}).services || [])[0] ||
                  {}
                ).color || "204, 204, 204, 1"
              })`;
              eventInfo.textColor = "black";
              const secretElement = `<i class="fas fa-lg  fa-user-secret right-secret-session" onclick='event.stopPropagation(); updateHiddenValue(${eventInfo.event.id});'></i>`;
              const unsecretElement = `<i class="fas fa-lg  fa-solid fa-solid fa-unlock right-secret-session" onclick='event.stopPropagation(); updateHiddenValue(${eventInfo.event.id});'></i>`;
              const htmlContainer = `<div>
                   ${
                     window.user &&
                     (window.user.isAdmin || window.user.isManagingAdmin)
                       ? eventInfo.event.extendedProps.hidden
                         ? secretElement
                         : unsecretElement
                       : ""
                   }
                <p>${eventInfo.timeText}</p>
                <p
                class="provider-name"
                ><b>P:</b> ${provider_name ? provider_name : "N/A"}</p>
                <p
                class="client-name"
                ><b>C:</b> ${client_name ? client_name : "N/A"}</p>
              </div>`;

              return { html: htmlContainer };
            },
          },
          timeGridDay: {
            eventContent: function (eventInfo) {
              const provider_name = eventInfo.event.extendedProps.providerName;
              const client_name = eventInfo.event._def.title;

              window
                .$(".fc-event-main div")
                .css({ "font-size": "15px", "text-shadow": "0 0 4px #ffffff" });
              eventInfo.backgroundColor = `rgba(${
                (
                  ((eventInfo.event.extendedProps || {}).services || [])[0] ||
                  {}
                ).color || "204, 204, 204, 1"
              })`;
              eventInfo.borderColor = `rgba(${
                (
                  ((eventInfo.event.extendedProps || {}).services || [])[0] ||
                  {}
                ).color || "204, 204, 204, 1"
              })`;
              eventInfo.textColor = "black";
              const secretElement = `<i class="fas fa-lg  fa-user-secret right-secret-session" onclick='event.stopPropagation(); updateHiddenValue(${eventInfo.event.id});'></i>`;
              const unsecretElement = `<i class="fas fa-lg  fa-solid fa-solid fa-unlock right-secret-session" onclick='event.stopPropagation(); updateHiddenValue(${eventInfo.event.id});'></i>`;
              const htmlContainer = `<div>
                  ${
                    window.user &&
                    (window.user.isAdmin || window.user.isManagingAdmin)
                      ? eventInfo.event.extendedProps.hidden
                        ? secretElement
                        : unsecretElement
                      : ""
                  }
                <p>${eventInfo.timeText}</p>
                <p
                class="provider-name"
                ><b>P:</b> ${provider_name ? provider_name : "N/A"}</p>
                <p
                class="client-name"
                ><b>C:</b> ${client_name ? client_name : "N/A"}</p>
              </div>`;

              return { html: htmlContainer };
            },
          },
        },
        plugins: [bootstrap, interactionPlugin, timeGridPlugin, dayGridPlugin],
        eventClick: this.handleEventClick,
      },
      companyLogoPlaceholder: require("@/assets/img/c3-logo.png"),
    };
  },
  mounted() {
    if (this.paramAdminUserId) {
      this.filter.Provider = this.paramAdminUserId;
      this.$router.replace({ query: {} });
    }

    this.$nextTick(() => {
      atcb_init();
    });
    if (this.user && !this.user.isPatient) {
      this.getCalendarPatients({ noLoading: !!this.profilePatientUserId });
    }
    if (this.user && this.user.isPatient) {
      this.getPatientProviders(this.user.patient_id);
    } else {
      this.getClassification();
      this.getCalendarProviders({
        noLoading: !!this.profileProviderUserId,
      }).then((providers) => {
        if (
          providers &&
          providers.length &&
          this.user &&
          this.user.isProvider
        ) {
          this.currentProvider = providers.filter(
            (provider) => provider.user_id == this.user.id
          )[0];
        }
      });
    }
    this.gettingUsers = true;
    this.getCalendarUsers().then(() => {
      this.gettingUsers = false;
      this.$forceUpdate();
    });
    calendarApi = this.$refs.fullCalendar.getApi();
    calendarApi.on("select", this.handleSelect);
    this.$http
      .get("services")
      .then(
        function (res) {
          this.parentServices = res.data.data.filter(
            (item) =>
              item.parent_service_id == null &&
              item.is_default != true &&
              item.unit != "KM"
          );
          this.parentServicesTemp = this.parentServices;

          this.nonBillableServices = this.parentServices.filter(
            (service) => !service.profession_id
          );
          this.mainNonBillableService = this.parentServices.filter(
            (service) =>
              !service.profession_id && service.name == "Non-billable Service"
          )[0];
          if (!this.mainNonBillableService) {
            const service = {
              catastrophic: false,
              description: "Non billable service",
              is_default: false,
              name: "Non-billable Service",
              code: "NON-BILLABLE",
              prov: "ON",
              rate: 0,
              taxable: true,
              unit: "HR",
              status: "ACTIVE",
            };
            this.createService(service).then((result) => {
              this.nonBillableServices = [...this.nonBillableServices, result];
            });
          }
          this.services = orderBy(res.data.data, ["is_default"], ["desc"]);
        }.bind(this)
      )
      .catch((err) => {
        if (!err.accessDenied) {
          Swal.fire({
            title: "Error",
            text: (err.data || {}).message || "Something went wrong...",
            icon: "error",
          });
        }
        this.openUpdateTray = false;
      });
  },
  created() {
    this.calendar.events = this.getApts;
    window.openCloneSessionModal = this.openCloneSessionModal.bind(this);
    window.updateHiddenValue = this.updateHiddenValue.bind(this);
    if (
      this.profileProviderUserId ||
      this.profilePatientUserId ||
      this.profileAdminUserId
    ) {
      this.getCompanyProfile();
    }
  },
  methods: {
    dateFormat: dateFormat,
    ...mapActions({
      getCalendarProviders: "providers/getCalendarProviders",
      getCalendarPatients: "patients/getCalendarPatients",
      getUserInfo: "auth/getUserInfo",
      getCalendarUsers: "security/getCalendarUsers",
      getClinicalNotesAptIds: "templates/getBasicClinicalNotesAptIds",
      createService: "services/create",
      getPatientProviders: "patients/providers/get",
      getCompanyProfile: "settings/getCompanyProfile",
      getClassification: "classifications/get",
    }),
    print: function () {
      window.print();
    },
    getUserName(id) {
      const user = this.users.filter((user) => user.id == id)[0];
      if (user) {
        return `${user.last}, ${user.first}`;
      }
      return "";
    },
    getSubServicesTotalHours: function (event) {
      return event.services.length > 1
        ? event.services
            .filter((serv) => serv.parent_service_id !== null)
            .reduce(
              (a, b) => a + (b.pivot.unit === "HR" ? b.pivot.quantity : 0),
              0
            )
        : 0;
    },
    openUsersAvailability: function () {
      window.open("/admin/users-availability", "_blank");
    },
    openUserAvailability: function () {
      this.openUserAvailabilityModal(
        this.profileAdminUserId ||
          this.profilePatientUserId ||
          this.profileProviderUserId
      );
    },
    openBulkSessionsModal: function () {
      this.bulkSessionsFilter = {
        ...JSON.parse(JSON.stringify(this.filter)),
        service: "",
        isVerifying: false,
        isUnverifying: false,
        dateRangeOption: "dateRange",
        range: {
          start: new Date(),
          end: new Date(),
        },
        customDates: [],
        customDatesIds: [],
      };
      if (this.profilePatientUserId) {
        this.bulkSessionsFilter.Patient = this.profilePatientUserId;
      } else if (this.profileProviderUserId) {
        this.bulkSessionsFilter.Provider = this.profileProviderUserId;
      } else if (this.profileAdminUserId) {
        this.bulkSessionsFilter.Provider = this.profileAdminUserId;
      }
      if (this.assignedToMe) {
        this.bulkSessionsFilter.Provider = this.user.id;
      }
      this.$modal.show("bulk-sessions-modal");
    },
    closeBulkSessionModal: function () {
      this.$modal.hide("bulk-sessions-modal");
    },
    verifyUnverifyBulkSessions: function (verify) {
      const vm = this;
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-black ml-4",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: `Are you sure you want to ${
            !verify ? "un" : ""
          }verify the filtered appointements?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Yes, ${!verify ? "un" : ""}verify!`,
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            const payload = {
              billableStatus: vm.bulkSessionsFilter.billableStatus,
              user_1: vm.bulkSessionsFilter.Provider,
              user_2: vm.bulkSessionsFilter.Patient,
              classes: vm.bulkSessionsFilter.classification,
              service: vm.bulkSessionsFilter.service,
            };
            if (vm.bulkSessionsFilter.dateRangeOption == "dateRange") {
              const startDateTime = `${dateFormat(
                vm.bulkSessionsFilter.range.start,
                "YYYY-MM-DD"
              )}T00:00:00.000000Z`;
              const endDateTime = `${dateFormat(
                vm.bulkSessionsFilter.range.end,
                "YYYY-MM-DD"
              )}T23:59:59.000000Z`;

              const canadaOffsetStart = dayjs
                .tz(new Date(startDateTime), "America/Toronto")
                .utcOffset();
              const canadaOffsetEnd = dayjs
                .tz(new Date(endDateTime), "America/Toronto")
                .utcOffset();
              payload.start_time = dayjs(startDateTime)
                .add(canadaOffsetStart, "minute")
                .utc()
                .format();

              payload.end_time = dayjs(endDateTime)
                .add(canadaOffsetEnd, "minute")
                .utc()
                .format();
            } else if (vm.bulkSessionsFilter.dateRangeOption == "customDates") {
              payload.custom_dates = vm.getAllBulkSelectedDates();
            }

            if (verify) {
              vm.bulkSessionsFilter.isVerifying = true;
            } else {
              vm.bulkSessionsFilter.isUnverifying = true;
            }
            vm.$http
              .put(`appointments/${!verify ? "un" : ""}verify-bulk`, payload)
              .then(
                (res) => {
                  vm.bulkSessionsFilter.isVerifying = false;
                  vm.bulkSessionsFilter.isUnverifying = false;
                  this.closeBulkSessionModal();
                  calendarApi.refetchEvents();
                  Swal.fire({
                    title: `${verify ? "Verified" : "Unverified"} Successfully`,
                    text: res.data?.message,
                    icon: "success",
                  });
                },
                (err) => {
                  calendarApi.refetchEvents();
                  let html =
                    err?.data?.error?.message?.error ||
                    err?.data?.error?.message ||
                    "Something went wrong...";
                  if (err.data.errors) {
                    html =
                      '<div class="alert alert-light-danger" role="alert">';
                    err.data.errors.forEach((error) => {
                      const startTime = dayjs(error.start_time)
                        .add(-1 * dayjs(error.start_time).utcOffset(), "minute")
                        .format("YYYY-MM-DD hh:mm (A)");
                      const endTime = dayjs(error.end_time)
                        .add(-1 * dayjs(error.end_time).utcOffset(), "minute")
                        .format("YYYY-MM-DD hh:mm (A)");
                      html += `<div>${error.message}<br><span class='small font-weight-bold' style='opacity: 0.7;'>${startTime}  -  ${endTime}</span></div><hr>`;
                    });
                  } else if (err.data.error && err.data.message) {
                    html = err.data.message;
                  }
                  Swal.fire({
                    html,
                  });
                  vm.bulkSessionsFilter.isVerifying = false;
                  vm.bulkSessionsFilter.isUnverifying = false;
                }
              );
          }
        });
    },
    setTimeToZero: function () {
      this.clickedEventDetails_endStr = this.clickedEventDetails_startStr;
      this.setChangesMade();
    },
    toggleDeletedStatus: function () {
      this.hideDeleted = !this.hideDeleted;
      calendarApi.refetchEvents();
    },
    onDayClick: function (day) {
      const idx = this.cloneFilter.customDatesIds.findIndex(
        (id) => id === day.id
      );
      if (idx >= 0) {
        this.cloneFilter.customDates.splice(idx, 1);
        this.cloneFilter.customDatesIds.splice(idx, 1);
      } else if (!day.el.classList.contains("is-disabled")) {
        this.cloneFilter.customDates.push(day.date);
        this.cloneFilter.customDatesIds.push(day.id);
      }
    },
    onBulkDayClick: function (day) {
      const idx = this.bulkSessionsFilter.customDatesIds.findIndex(
        (id) => id === day.id
      );
      if (idx >= 0) {
        this.bulkSessionsFilter.customDates.splice(idx, 1);
        this.bulkSessionsFilter.customDatesIds.splice(idx, 1);
      } else if (!day.el.classList.contains("is-disabled")) {
        this.bulkSessionsFilter.customDates.push(day.date);
        this.bulkSessionsFilter.customDatesIds.push(day.id);
      }
    },
    addToExternalCalendar: function () {
      let data = {
        name: `${this.clickedEventDetails_service.name} - ${this.clickedEventDetails_title}`,
        description: `Service: ${
          this.clickedEventDetails_service.name
        }<br>Organizer: ${this.clickedEventDetails_organizer}<br>Invited: ${
          this.clickedEventDetails_title
        }<br>Reason: ${this.clickedEventDetails_reason || "-"}<br>Memo: ${
          this.clickedEventDetails_memo || "-"
        }<br>Type: ${this.clickedEventDetails_type}${
          this.clickedEventDetails_meetingUrl
            ? "<br>Join URL: " + this.clickedEventDetails_meetingUrl
            : ""
        }`,
        startDate: this.getPersonalCalendarDate(
          this.clickedEventDetails_startStr,
          "date"
        ),
        endDate: this.getPersonalCalendarDate(
          this.clickedEventDetails_endStr,
          "date"
        ),
        startTime: this.getPersonalCalendarDate(
          this.clickedEventDetails_startStr,
          "time"
        ),
        endTime: this.getPersonalCalendarDate(
          this.clickedEventDetails_endStr,
          "time"
        ),
        location: "",
        timeZone: dayjs.tz.guess(),
        options: [
          "Apple",
          "Google",
          "Microsoft365",
          "Outlook.com|Outlook",
          "MicrosoftTeams",
          // "Yahoo",
          "iCal",
        ],
        trigger: "click",
        listStyle: "list",
        inline: true,
        iCalFileName: `Reminder, ${this.clickedEventDetails_service.name} - ${this.clickedEventDetails_title}`,
      };
      atcb_action(data, this.$refs.externalCalendarButton);
    },
    sortAlphabetically(arrayInput) {
      const arraySrc = new Array(...arrayInput);
      const result = arraySrc.sort((a, b) => {
        if (`${a.last} ${a.first}` < `${b.last} ${b.first}`) {
          return -1;
        }
        if (`${a.last} ${a.first}` > `${b.last} ${b.first}`) {
          return 1;
        }
        return 0;
      });
      return result;
    },
    getPersonalCalendarDate: function (value, type) {
      if (type == "date") {
        return dayjs(value).format("YYYY-MM-DD");
      } else {
        return dayjs(value).format("HH:mm");
      }
    },
    showServicesBasedOnSelectedProvider(selectedUserObj, isBooking) {
      let selectedProviderId = selectedUserObj;
      let isAdmin = false;
      if (selectedUserObj != null && selectedUserObj.toString().includes("_")) {
        selectedProviderId = selectedUserObj.split("_")[0];
        if (selectedUserObj.split("_")[1] == "true") {
          isAdmin = true;
        }
      }

      this.setChangesMade();
      if (isBooking) {
        if (this.isBillable) {
          this.booking.primary = 0;
        } else {
          this.booking.primary = (this.mainNonBillableService || {}).id;
        }
      } else {
        if (this.isBillable) {
          this.clickedEventDetails_service.id = 0;
        } else {
          this.clickedEventDetails_service.id = (
            this.mainNonBillableService || {}
          ).id;
        }
      }
      let vm = this;
      if (!vm.isBillable && vm.nonBillableServices.length) {
        vm.parentServices = vm.nonBillableServices;
        return;
      }

      let selectedUser = this.users.filter(
        (item) => item.id == selectedProviderId
      )[0];

      if (!isAdmin && !((selectedUser || {}).professions || []).length) {
        selectedUser = this.providers.filter(
          (item) => item.user_id == selectedProviderId
        )[0];
      }

      //show error message
      if (
        !selectedUser ||
        selectedUser.isAdmin ||
        selectedUser.isManagingAdmin
      ) {
        vm.parentServices = vm.parentServicesTemp;
        if (isBooking) {
          vm.parentServices = vm.parentServices.filter(
            (serv) => serv.status !== "INACTIVE"
          );
        }
        if (isBooking && this.booking.patient) {
          const selectedPatient = this.patients.filter(
            (patient) => patient.user_id == this.booking.patient
          )[0];
          if (selectedPatient && selectedPatient.services.length) {
            vm.parentServices = vm.parentServices.filter((serv) =>
              selectedPatient.services.includes(serv.id)
            );
          }
        } else if (this.clickedEventDetails_patient_user_id) {
          const selectedPatient = this.patients.filter(
            (patient) =>
              patient.user_id == this.clickedEventDetails_patient_user_id
          )[0];
          if (selectedPatient && selectedPatient.services.length) {
            vm.parentServices = vm.parentServices.filter((serv) =>
              selectedPatient.services.includes(serv.id)
            );
          }
        }
        return;
      } else if (
        vm.isBillable &&
        (selectedUser.professions || []).length == 0
      ) {
        vm.parentServices = [];

        return Swal.fire({
          title: "Warning",
          text:
            this.user && this.user.isProvider
              ? "There are no services attached to you yet"
              : "The selected provider/Admin has no attached services",
          icon: "warning",
        });
      }
      if (vm.isBillable) {
        // show all parent services that has the provider profession
        vm.parentServices = [];
        selectedUser.professions.forEach(function (profession) {
          vm.parentServicesTemp.forEach(function (item) {
            if (item.profession_id == profession.id) {
              vm.parentServices.push(item);
            } else if (
              vm.clickedEventDetails_service &&
              item.id == vm.clickedEventDetails_service?.pivot?.service_id
            ) {
              vm.parentServices.push({ ...item, status: "INACTIVE" });
            }
          });
        });
        if (isBooking && this.booking.patient) {
          const selectedPatient = this.patients.filter(
            (patient) => patient.user_id == this.booking.patient
          )[0];
          if (selectedPatient && selectedPatient.services.length) {
            vm.parentServices = vm.parentServices.filter(
              (serv) =>
                selectedPatient.services.includes(serv.id) ||
                serv.id == this.clickedEventDetails_service?.pivot?.service_id
            );
          }
        } else if (this.clickedEventDetails_patient_user_id) {
          const selectedPatient = this.patients.filter(
            (patient) =>
              patient.user_id == this.clickedEventDetails_patient_user_id
          )[0];
          if (selectedPatient && selectedPatient.services.length) {
            vm.parentServices = vm.parentServices.filter(
              (serv) =>
                selectedPatient.services.includes(serv.id) ||
                serv.id == this.clickedEventDetails_service?.pivot?.service_id
            );
          }
        }
      }
    },
    showHideAutoVirtualMeeting(eventType) {
      this.setChangesMade();
      if (eventType == "VIRTUAL") {
        this.showAutoScheduleOption = true;
      } else {
        this.showAutoScheduleOption = false;
      }
    },
    setChangesMade() {
      this.changesMade = true;
    },
    selectTab(id) {
      window.$(id).trigger("click");
    },
    clearEventDetailsData() {
      this.clickedEventDetails_type = null;
      this.clickedEventDetails_providerRate = 0;
      this.clickedEventDetails_id = "";
      this.clickedEventDetails_startStr = "";
      this.clickedEventDetails_endStr = "";
      this.clickedEventDetails_title = "";
      this.clickedEventDetails_start = null;
      this.clickedEventDetails_end = null;
      this.clickedEventDetails_primary = null;
      this.clickedEventDetails_service = null;
      this.clickedEventDetails_deleted = false;
      this.clickedEventDetails_end_time = "";
      this.clickedEventDetails_start_time = "";
      this.clickedEventDetails_status = "";
      this.clickedEventDetails_memo = "";
      this.clickedEventDetails_reason = "";
      this.clickedEventDetails_createdBy = "";
      this.clickedEventDetails_createdAt = "";
      this.clickedEventDetails_deletedBy = "";
      this.clickedEventDetails_deletedAt = "";
      this.clickedEventDetails_verifiedBy = "";
      this.clickedEventDetails_verifiedAt = "";
      this.clickedEventDetails_expenses = 0;
      this.clickedEventDetails_billing_details = "";
      this.clickedEventDetails_patient = null;
      this.clickedEventDetails_provider = null;
      this.clickedEventDetails_mainService = null;
      this.clickedEventDetails_services = [];
      this.booking = {
        start: null,
        end: null,
        provider: null,
        patient: null,
        primary: null,
        type: "",
        provider_rate: 0.0,
      };
    },
    applyServerFilter: function () {
      if (this.profilePatientUserId) {
        this.filter.Patient = this.profilePatientUserId;
      } else if (this.profileProviderUserId) {
        this.filter.Provider = this.profileProviderUserId;
      } else if (this.profileAdminUserId) {
        this.filter.Provider = this.profileAdminUserId;
      }
      if (this.assignedToMe) {
        this.filter.Provider = this.user.id;
      }
      calendarApi.refetchEvents();
    },
    applyFilter: function () {
      let vm = this;
      const eventsToPrint = [];
      let totalBillable = 0;
      let totalNonBillable = 0;
      calendarApi.batchRendering(function () {
        let events = calendarApi.getEvents();
        for (let i = 0; i < events.length; i++) {
          let event = events[i];
          event.calendarRef = vm;
          if (event.extendedProps.status == "Verified") {
            event.extendedProps.services.forEach((service) => {
              if (service.unit == "HR" && service.profession_id) {
                totalBillable += service.pivot.quantity;
              } else if (service.unit == "HR") {
                totalNonBillable += service.pivot.quantity;
              }
            });
          }
          eventsToPrint.push({
            ...event.extendedProps,
            start: event.start,
            id: event.id,
          });
        }

        const billableHours = parseInt(totalBillable);
        const billableMinutes = ((totalBillable - billableHours) * 60).toFixed(
          0
        );
        vm.totalBillable = `${billableHours}h${
          parseInt(billableMinutes) ? ", " + billableMinutes + "m" : ""
        }`;

        const nonBillableHours = parseInt(totalNonBillable);
        const nonBillableMinutes = (
          (totalNonBillable - nonBillableHours) *
          60
        ).toFixed(0);
        vm.totalNonBillable = `${nonBillableHours}h${
          parseInt(nonBillableMinutes) ? ", " + nonBillableMinutes + "m" : ""
        }`;

        vm.eventsToPrint = eventsToPrint;
        if (!events.length) {
          if (vm.profileProviderUserId) {
            vm.selectedProvider = vm.users.filter(
              (user) => user.id == vm.profileProviderUserId
            )[0];
          }
          if (vm.profilePatientUserId) {
            vm.selectedPatient = vm.users.filter(
              (user) => user.id == vm.profilePatientUserId
            )[0];
          }
        }
      });
    },
    handleEventClick: function (clickInfo) {
      this.clickedEventDetails_startStr = dayjs(
        clickInfo.event.extendedProps.start_time
      )
        .add(
          -1 * dayjs(clickInfo.event.extendedProps.start_time).utcOffset(),
          "minute"
        )
        .format();

      this.clickedEventDetails_endStr = dayjs(
        clickInfo.event.extendedProps.end_time
      )
        .add(
          -1 * dayjs(clickInfo.event.extendedProps.end_time).utcOffset(),
          "minute"
        )
        .format();
      this.clickedEventDetails_hidden = clickInfo.event.extendedProps.hidden;
      this.clickedEventDetails_id = clickInfo.event.id;
      this.clickedEventDetails_title = clickInfo.event.title;
      this.clickedEventDetails_start = clickInfo.event.extendedProps.start;
      this.clickedEventDetails_end = clickInfo.event.extendedProps.end;
      this.clickedEventDetails_primary = clickInfo.event.extendedProps.primary;
      const user1 = this.getProvider(clickInfo.event.extendedProps);
      this.clickedEventDetails_organizer = `${user1.last}, ${user1.first}`;
      this.clickedEventDetails_deleted = clickInfo.event.extendedProps.deleted;
      this.clickedEventDetails_service =
        clickInfo.event.extendedProps.services[0] || {};
      this.clickedEventDetails_end_time = dayjs(
        clickInfo.event.extendedProps.end_time
      )
        .add(
          -1 * dayjs(clickInfo.event.extendedProps.end_time).utcOffset(),
          "minute"
        )
        .format();
      this.clickedEventDetails_start_time = dayjs(
        clickInfo.event.extendedProps.start_time
      )
        .add(
          -1 * dayjs(clickInfo.event.extendedProps.start_time).utcOffset(),
          "minute"
        )
        .format();
      this.clickedEventDetails_status = clickInfo.event.extendedProps.status;
      this.clickedEventDetails_type = clickInfo.event.extendedProps.type;
      this.clickedEventDetails_memo = clickInfo.event.extendedProps.memo;
      this.clickedEventDetails_reason = clickInfo.event.extendedProps.reason;
      this.clickedEventDetails_createdBy =
        clickInfo.event.extendedProps.created_by;
      this.clickedEventDetails_createdAt =
        clickInfo.event.extendedProps.created_at &&
        clickInfo.event.extendedProps.created_at != "-"
          ? dayjs(clickInfo.event.extendedProps.created_at).format(
              "MMM DD, YYYY  hh:mm A"
            )
          : "-";
      this.clickedEventDetails_deletedBy =
        clickInfo.event.extendedProps.deleted_by;
      this.clickedEventDetails_deletedAt =
        clickInfo.event.extendedProps.deleted_at &&
        clickInfo.event.extendedProps.deleted_at != "-"
          ? dayjs(clickInfo.event.extendedProps.deleted_at).format(
              "MMM DD, YYYY  hh:mm A"
            )
          : "-";
      this.clickedEventDetails_verifiedBy =
        clickInfo.event.extendedProps.verified_by;
      this.clickedEventDetails_verifiedAt =
        clickInfo.event.extendedProps.verified_at &&
        clickInfo.event.extendedProps.verified_at != "-"
          ? dayjs(clickInfo.event.extendedProps.verified_at)
              .add(
                dayjs(clickInfo.event.extendedProps.verified_at).utcOffset(),
                "minute"
              )
              .format("MMM DD, YYYY  hh:mm A")
          : "-";
      this.clickedEventDetails_expenses =
        clickInfo.event.extendedProps.expenses;
      this.clickedEventDetails_billing_details =
        clickInfo.event.extendedProps.billing_details;
      this.clickedEventDetails_providerRate =
        clickInfo.event.extendedProps.provider_rate;

      // If admin / provider can start the meeting as a host, if patient, will joing the meeting as a guest
      if (
        (this.user && this.user.isAdmin) ||
        this.user.isManagingAdmin ||
        this.user.isProvider
      ) {
        this.clickedEventDetails_meetingUrl =
          clickInfo.event.extendedProps.start_url;
      } else {
        this.clickedEventDetails_meetingUrl =
          clickInfo.event.extendedProps.join_url;
      }

      this.clickedEventDetails_patient =
        clickInfo.event.extendedProps.patient ||
        clickInfo.event.extendedProps.user_2;
      this.clickedEventDetails_patient_user_id =
        this.clickedEventDetails_patient.user_id ||
        this.clickedEventDetails_patient.id;

      this.clickedEventDetails_provider =
        clickInfo.event.extendedProps.provider ||
        clickInfo.event.extendedProps.user_1;
      this.clickedEventDetails_provider_user_id =
        this.clickedEventDetails_provider.user_id ||
        this.clickedEventDetails_provider.id;

      this.isBillable = clickInfo.event.extendedProps.billable == "1";
      this.allowOverlap = false;

      const currentService = (this.clickedEventDetails_service || {}).id;
      this.showServicesBasedOnSelectedProvider(
        this.clickedEventDetails_provider.id
      );

      this.clickedEventDetails_service.id = currentService;

      this.clickedEventDetails_mainService =
        clickInfo.event.extendedProps.services[0];
      const selectedPatient = this.patients.filter(
        (patient) => patient.user_id == this.clickedEventDetails_patient_user_id
      )[0];
      if (
        this.clickedEventDetails_mainService &&
        this.clickedEventDetails_mainService.profession_id &&
        selectedPatient &&
        (selectedPatient.services || []).length &&
        !selectedPatient.services.includes(
          this.clickedEventDetails_mainService.id
        )
      ) {
        this.parentServices.push({
          ...this.parentServicesTemp.filter(
            (serv) => serv.id == this.clickedEventDetails_mainService.id
          )[0],
          status: "INACTIVE",
        });
      }
      var clickedEventDetails_selectedServices = null;
      if (clickInfo.event.extendedProps.services.length > 1) {
        clickedEventDetails_selectedServices =
          clickInfo.event.extendedProps.services.filter(
            (item) =>
              item.parent_service_id != null || item.is_default == "true"
          );
      }
      var parentService = this.clickedEventDetails_mainService;
      // Filter it with services where the parent Id is the service Id of the event.
      var eventSubServices = [];
      let vm = this;
      this.services.forEach(function (item) {
        if (
          clickedEventDetails_selectedServices != null &&
          clickedEventDetails_selectedServices != undefined
        ) {
          var selectedItem2 = clickedEventDetails_selectedServices.filter(
            (selectedItem) =>
              selectedItem.id == item.id && selectedItem.is_default == "true"
          );

          if (selectedItem2 != null && selectedItem2.length > 0) {
            item = selectedItem2[0];
            item.selected = true;
            if (item.unit == "FT" || item.unit == "FLAT") {
              item.qty = 1;
            }
            eventSubServices.push(item);
            return item;
          }
        }

        if (
          parentService != null &&
          item.parent_service_id == parentService.id
        ) {
          // If this is in the sent array then it was selected before, return the saved value and set it as selected

          if (
            clickedEventDetails_selectedServices != null &&
            clickedEventDetails_selectedServices != undefined
          ) {
            var selectedItem = clickedEventDetails_selectedServices.filter(
              (selectedItem) => selectedItem.id == item.id
            );
          }
          if (selectedItem != null && selectedItem.length > 0) {
            item = selectedItem[0];
            item.selected = true;
            if (item.pivot) {
              item.description = item.pivot.description;
              if (vm.clickedEventDetails_status !== "Verified") {
                item.pivot.rate = item.rate;
                item.pivot.unit = item.unit;
                item.pivot.taxable = item.taxable;
              }
            }
            if (item.unit == "FT" || item.unit == "FLAT") {
              item.qty = 1;
            }
            eventSubServices.push(item);
            return item;
          }
          item.selected = false;
          if (item.pivot) {
            item.description = item.pivot.description;
          }
          eventSubServices.push(item);
          return item;
        }
      });
      this.clickedEventDetails_services = JSON.parse(
        JSON.stringify(eventSubServices)
      );
      this.openUpdateTray = true;
      setTimeout(() => {
        this.changesMade = false;
      }, 0);
    },
    getSubServicesByParentServiceId(
      parentServiceID,
      clickedEventDetails_selectedServices
    ) {
      this.setChangesMade();
      this.updateProviderRate(
        this.openUpdateTray
          ? this.clickedEventDetails_provider_user_id
          : this.booking.provider,
        parentServiceID
      );
      var eventSubServices = this.services.filter(function (item) {
        if (item.is_default) {
          item.selected = true;
          item.qty = 1;
          return item;
        }
        if (item.unit == "FT" || item.unit == "FLAT") {
          item.qty = 1;
        }

        if (item.parent_service_id == parentServiceID) {
          // If this is in the sent array then it was selected before, return the saved value and set it as selected
          if (
            clickedEventDetails_selectedServices != null &&
            clickedEventDetails_selectedServices != undefined
          ) {
            var selectedItem = clickedEventDetails_selectedServices.filter(
              (selectedItem) => selectedItem.id == item.id
            );
          }
          if (selectedItem != null && selectedItem.length > 0) {
            item = selectedItem[0];
            item.selected = true;
            return item;
          }

          item.selected = false;
          return item;
        }
      });

      this.clickedEventDetails_services = JSON.parse(
        JSON.stringify(eventSubServices)
      );
      if (this.openTray) {
        this.clickedEventDetails_services =
          this.clickedEventDetails_services.filter(
            (sub) => sub.status !== "INACTIVE"
          );
      }
    },
    updateSelectedService(value) {
      this.clickedEventDetails_mainService = this.parentServices.filter(
        (service) => {
          return service.id == this.clickedEventDetails_service.id;
        }
      )[0];
      this.getSubServicesByParentServiceId(value, null);
      this.eventModified = true;
    },
    billableStatusChanged(isBooking) {
      this.setChangesMade();
      if (isBooking) {
        if (!this.isBillable) {
          this.booking.provider = null;
          this.booking.patient = null;
          this.booking.provider_rate = 0.0;
          if (this.user && this.user.isProvider) {
            this.booking.provider = this.user.id;
          }
          if (this.profilePatientUserId) {
            this.booking.patient = this.profilePatientUserId;

            this.booking.provider = `${this.user.id}_${
              this.user.isAdmin ? "true" : "false"
            }`;
            this.showServicesBasedOnSelectedProvider(
              this.booking.provider,
              true
            );
            this.updateProviderRate(
              this.booking.provider,
              this.booking.primary
            );
          } else {
            this.showServicesBasedOnSelectedProvider(
              this.booking.provider,
              true
            );
            this.updateProviderRate(
              this.booking.provider,
              this.booking.primary
            );
          }
          this.showServicesBasedOnSelectedProvider(this.booking.provider, true);
        } else {
          this.booking.provider = null;
          this.booking.patient = null;
          if (this.user && this.user.isProvider) {
            this.booking.provider = this.user.id;
          }
          if (this.profilePatientUserId) {
            this.booking.patient = this.profilePatientUserId;
            this.booking.provider = `${this.user.id}_${
              this.user.isAdmin ? "true" : "false"
            }`;
            this.showServicesBasedOnSelectedProvider(
              this.booking.provider,
              true
            );
            this.updateProviderRate(
              this.booking.provider,
              this.booking.primary
            );
          } else {
            this.showServicesBasedOnSelectedProvider(
              this.booking.provider,
              true
            );
            this.updateProviderRate(
              this.booking.provider,
              this.booking.primary
            );
          }
          this.showServicesBasedOnSelectedProvider(this.booking.provider, true);
        }
      }
    },
    closeUpdateTray: function () {
      if (this.eventModified) {
        calendarApi.refetchEvents();
        this.eventModified = false;
      }
      this.openUpdateTray = false;
    },
    getApts: function (info, successCb) {
      this.loading = true;

      if (!this.userInfo) {
        this.getUserInfo()
          .then(() => {
            this.sendAptRequest(info, successCb);
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        this.sendAptRequest(info, successCb);
      }
    },
    sendAptRequest: function (info, successCb) {
      if (this.sentAptRequests.length) {
        while (this.sentAptRequests.length) {
          const requestToAbort = this.sentAptRequests.pop();
          requestToAbort.abort();
        }
      }
      const abort_controller = new AbortController();
      this.events = [];
      this.eventsBackup = [];
      var requestUrl = "appointments";
      if (this.user && this.user.isPatient && this.userInfo) {
        requestUrl = "patients/" + this.userInfo.user_id + "/appointments";
      } else if (this.user && this.user.isProvider && this.userInfo) {
        requestUrl = "providers/" + this.userInfo.user_id + "/appointments";
      }
      if (this.profileProviderUserId) {
        requestUrl =
          "providers/" + this.profileProviderUserId + "/appointments";
      }
      if (this.profileAdminUserId) {
        requestUrl = "providers/" + this.profileAdminUserId + "/appointments";
      }
      if (this.profilePatientUserId) {
        requestUrl = "patients/" + this.profilePatientUserId + "/appointments";
      }
      if (this.user && this.userInfo) {
        const startDateTime = `${dateFormat(
          info.start,
          "YYYY-MM-DD"
        )}T00:00:00.000000Z`;
        const endDateTime = `${dateFormat(
          dayjs(info.end).subtract(1, "day"),
          "YYYY-MM-DD"
        )}T23:59:59.000000Z`;

        const canadaOffsetStart = dayjs
          .tz(new Date(startDateTime), "America/Toronto")
          .utcOffset();
        const canadaOffsetEnd = dayjs
          .tz(new Date(endDateTime), "America/Toronto")
          .utcOffset();
        const payloadStart = dayjs(startDateTime)
          .add(canadaOffsetStart, "minute")
          .utc()
          .format();
        const payloadEnd = dayjs(endDateTime)
          .add(canadaOffsetEnd, "minute")
          .utc()
          .format();

        let query = `?start=${payloadStart}&end=${payloadEnd}&trashed=${!this
          .hideDeleted}`;

        if (this.filter.classification) {
          query = `${query}&class_id=${this.filter.classification}`;
        }
        if (this.filter.Patient) {
          query = `${query}&client_id=${this.filter.Patient}`;
        } else if (this.profilePatientUserId) {
          query = `${query}&client_id=${this.profilePatientUserId}`;
        }
        if (this.filter.Provider || this.assignedToMe) {
          query = `${query}&provider_id=${
            this.filter.Provider || this.user.id
          }`;
        }
        if (this.filter.billableStatus) {
          query = `${query}&billable_status=${this.filter.billableStatus}`;
        }

        this.currentRequest = this.$http
          .get(requestUrl + query, {
            signal: abort_controller.signal,
          })
          .then(
            function (res) {
              let vm = this;
              vm.events = [];
              this.loading = false;
              if (res) {
                res.data.data.map((d) => {
                  try {
                    var insideThis = this;
                    d.providerName = `${(d.provider || d.user_1 || {}).last}, ${
                      (d.provider || d.user_1 || {}).first
                    }`;
                    d.patientName = `${(d.patient || d.user_2 || {}).last}, ${
                      (d.patient || d.user_2 || {}).first
                    }`;
                    if (insideThis.user != null && insideThis.user.isPatient) {
                      d.isloggedinPatient = true;
                      d.title = d.providerName;
                    } else {
                      d.title = d.patientName;
                    }
                  } catch (e) {
                    d.title = "";
                  }

                  if (d.status == "Ready for Review") {
                    d.backgroundColor = "#009688";
                  } else if (d.status == "PENDING") {
                    d.backgroundColor = "#9d6aa6";
                  } else if (d.status == "Verified") {
                    d.backgroundColor = "darkgreen";
                  }
                  d.start = dayjs(d.start_time)
                    .add(-1 * dayjs(d.start_time).utcOffset(), "minute")
                    .format();
                  d.end = dayjs(d.end_time)
                    .add(-1 * dayjs(d.end_time).utcOffset(), "minute")
                    .format();

                  let hours = 0;
                  try {
                    const date1 = dayjs(d.end_time);
                    hours = date1.diff(d.start_time, "hour", true);
                  } catch (err) {
                    console.log(err);
                  }

                  const minutes = (hours - parseInt(hours)) * 60;
                  d.hours = parseInt(hours);
                  d.minutes = minutes.toFixed(0);
                  vm.events = [...vm.events, d];
                });
                vm.eventsBackup = vm.events;
                successCb(vm.events);
              }
              vm.applyFilter();
            }.bind(this)
          )
          .catch((err) => {
            if (abort_controller.signal.aborted) {
              return;
            }
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: (err.data || {}).message || "Something went wrong...",
                icon: "error",
              });
            }
            this.loading = false;
            this.openUpdateTray = false;
          });
        this.sentAptRequests.push(abort_controller);
      } else {
        this.loading = false;
      }
    },
    close: function () {
      this.openTray = false;
      calendarApi.unselect();
    },
    unapproveEvent: function () {
      this.actionInProgress = true;
      this.$http
        .put("appointments/" + this.clickedEventDetails_id + "/unverify")
        .then(
          function (res) {
            this.actionInProgress = false;
            if (res) {
              this.openUpdateTray = false;
              Swal.fire({
                title: "Appointment Un-Verified ",
                text: "Appointment Un-Verified Successfully",
                icon: "success",
              });
              calendarApi.refetchEvents();
            }
          }.bind(this)
        )
        .catch((err) => {
          this.actionInProgress = false;
          if (!err.accessDenied) {
            Swal.fire({
              title: "Error",
              text: (err.data || {}).message || "Something went wrong...",
              icon: "error",
            });
          }
          this.openUpdateTray = false;
        });
    },
    approveEvent: function () {
      if (this.changesMade) {
        const vm = this;
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: "btn btn-black",
            cancelButton: "btn btn-primary ml-4",
          },
          buttonsStyling: false,
        });

        return swalWithBootstrapButtons
          .fire({
            title: "Changes made to this event",
            text: "You made some changes to this event, do you want to save them?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Save & Verify",
            cancelButtonText: "Verify Only",
          })
          .then((result) => {
            if (result.isConfirmed) {
              vm.UpdateBook(true).then((res) => {
                if (res) {
                  vm.confirmApproveEvent();
                }
              });
            } else if (result.isDismissed && result.dismiss == "cancel") {
              vm.confirmApproveEvent();
            }
          });
      } else {
        this.confirmApproveEvent();
      }
    },
    confirmApproveEvent: function () {
      this.loading = true;
      this.actionInProgress = true;
      const patient = this.patients.filter(
        (patient) => patient.user_id == this.clickedEventDetails_patient.id
      )[0];
      this.getClinicalNotesAptIds((patient || {}).id).then((res) => {
        this.loading = false;
        this.actionInProgress = false;
        if (!res) {
          return;
        }
        const aptClinicalNote = res.filter(
          (note) => note.appointment_id == this.clickedEventDetails_id
        );
        if (
          (aptClinicalNote.length || !this.isBillable) &&
          !this.user.isProvider
        ) {
          this.sendApproveEventRequest();
        } else {
          const vm = this;
          const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
              confirmButton: "btn btn-black ml-4",
              cancelButton: "btn btn-danger",
            },
            buttonsStyling: false,
          });

          let text = "You haven't created a clinical note for this event yet!";
          if (this.user.isProvider) {
            text =
              "Once verified, you will not be able to undo this action without the assistance of the file administrator";
            if (this.canHaveClinicalNotes()) {
              text =
                "You haven't created a clinical note for this event yet!<br><br>Once verified, you will not be able to undo this action without the assistance of the file administrator";
            }
          }
          return swalWithBootstrapButtons
            .fire({
              title: "Are you sure you want to verify?",
              html: `<p class="text-left">${text}</p>`,
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Yes, verify!",
              cancelButtonText: "No, cancel!",
              reverseButtons: true,
            })
            .then((result) => {
              if (result.isConfirmed) {
                vm.sendApproveEventRequest();
              }
            });
        }
      });
    },
    approveNewEvent: function (id) {
      const vm = this;
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-black ml-4",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });

      if (this.canHaveClinicalNotes() || this.user.isProvider) {
        let text = "You haven't created a clinical note for this event yet!";
        if (this.user.isProvider) {
          text =
            "Once verified, you will not be able to undo this action without the assistance of the file administrator";
          if (this.canHaveClinicalNotes()) {
            text =
              "You haven't created a clinical note for this event yet!<br><br>Once verified, you will not be able to undo this action without the assistance of the file administrator";
          }
        }
        return swalWithBootstrapButtons
          .fire({
            title: "Are you sure you want to verify?",
            html: `<p class="text-left">${text}</p>`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, verify!",
            cancelButtonText: "No, cancel!",
            reverseButtons: true,
          })
          .then((result) => {
            if (result.isConfirmed) {
              vm.sendApproveEventRequest(id);
            } else if (id) {
              calendarApi.refetchEvents();
              this.openTray = false;
            }
          });
      } else {
        vm.sendApproveEventRequest(id);
      }
    },
    sendApproveEventRequest: function (id) {
      this.actionInProgress = true;
      this.$http
        .put("appointments/" + (id || this.clickedEventDetails_id) + "/verify")
        .then(
          function (res) {
            this.actionInProgress = false;
            if (res) {
              if (id) {
                this.openTray = false;
              } else {
                this.openUpdateTray = false;
              }
              Swal.fire({
                title: "Appointment Verified ",
                text: "Appointment Verified Successfully",
                icon: "success",
              });
              calendarApi.refetchEvents();
            }
          }.bind(this)
        )
        .catch((err) => {
          this.false = true;
          if (!err.accessDenied) {
            Swal.fire({
              title: "Error",
              text: (err.data || {}).message || "Something went wrong...",
              icon: "error",
            });
          }
          this.openUpdateTray = false;
        });
    },
    DeleteEvent: function () {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-black ml-4",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });

      let text = "You are going to delete this session";
      if (this.clickedEventDetails_status == "Verified") {
        text =
          "This booking has been verified, are you sure you want to delete?";
      }
      return swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete",
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.actionInProgress = true;
            this.$http
              .delete("appointments/" + this.clickedEventDetails_id)
              .then(
                function (res) {
                  this.actionInProgress = false;
                  if (res) {
                    this.openUpdateTray = false;
                    Swal.fire({
                      title: "Appointment Deleted",
                      text: "Appointment Deleted Successfully",
                      icon: "success",
                    });
                    calendarApi.refetchEvents();
                  }
                }.bind(this)
              )
              .catch((err) => {
                this.actionInProgress = false;
                if (!err.accessDenied) {
                  Swal.fire({
                    title: "Error",
                    text: (err.data || {}).message || "Something went wrong...",
                    icon: "error",
                  });
                }
                this.openUpdateTray = false;
              });
          }
        });
    },
    UpdateBook: function (verify = false) {
      if (this.clickedEventDetails_status === "Verified") {
        Swal.fire({
          title: "Warning",
          text: `Verified sessions can't be updated`,
          icon: "warning",
        });
        return;
      }
      const canadaOffset = dayjs
        .tz(new Date(this.clickedEventDetails_startStr), "America/Toronto")
        .utcOffset();
      const sessionTimeDiff = dayjs(this.clickedEventDetails_endStr).diff(
        this.clickedEventDetails_startStr,
        "s"
      );
      if (sessionTimeDiff < 0) {
        this.selectTab("#nav-home-tab");
        Swal.fire({
          title: "Error",
          text: `Session end time is earlier than start time!`,
          icon: "error",
        });
        return Promise.resolve(false);
      } else if (
        sessionTimeDiff == 0 &&
        (this.clickedEventDetails_mainService.unit == "FT" ||
          this.clickedEventDetails_mainService.unit == "FLAT")
      ) {
        this.selectTab("#nav-home-tab");
        Swal.fire({
          title: "Error",
          text: `Session end time is equal to start time!`,
          icon: "error",
        });
        return Promise.resolve(false);
      }
      let data = {
        id: this.clickedEventDetails_id,
        start_time: dayjs(this.clickedEventDetails_startStr)
          .add(canadaOffset, "minute")
          .add(dayjs(this.clickedEventDetails_startStr).utcOffset(), "minute")
          .utc()
          .format(),
        end_time: dayjs(this.clickedEventDetails_endStr)
          .add(dayjs(this.clickedEventDetails_endStr).utcOffset(), "minute")
          .utc()
          .add(canadaOffset, "minute")
          .format(),
        user_2:
          this.clickedEventDetails_patient != null
            ? this.clickedEventDetails_patient_user_id
            : "",
        user_1: this.user.isProvider
          ? this.user.id
          : parseInt(this.clickedEventDetails_provider_user_id),
        memo: this.clickedEventDetails_memo,
        reason: this.clickedEventDetails_reason,
        expenses: this.isBillable ? this.clickedEventDetails_expenses : 0,
        billing_details: this.isBillable
          ? this.clickedEventDetails_billing_details
          : "",
        type: this.clickedEventDetails_type,
        primary_service: this.clickedEventDetails_mainService,
        provider_rate: this.clickedEventDetails_providerRate,
        billable: this.isBillable,
        allow_overlap: this.allowOverlap,
        hidden: this.clickedEventDetails_hidden,
      };
      if (
        this.clickedEventDetails_status != null &&
        this.clickedEventDetails_status != undefined
      ) {
        data.services = this.clickedEventDetails_services.filter(
          (item) => item.selected == true
        );
      }
      let hasEmptyQuantity = false;
      let hasNegativeQuantity = false;
      data.services.forEach((service) => {
        if (service.pivot != null && service.pivot != undefined) {
          service.qty = service.pivot.quantity;
          service.rate = service.pivot.rate;
          service.unit = service.pivot.unit;
          service.taxable = service.pivot.taxable == "1" ? true : false;
        }
        service.description = new String(service.description || "").substring(
          0,
          500
        );
        if (!service.qty) {
          hasEmptyQuantity = true;
        } else if (service.qty < 0) {
          hasNegativeQuantity = true;
        }
      });

      if (hasEmptyQuantity || hasNegativeQuantity) {
        this.selectTab("#nav-profile-tab");
        Swal.fire({
          title: "Error",
          text: hasEmptyQuantity
            ? "Quantity in selected sub services is required!"
            : "Quantity in selected sub services can't be negative!",
          icon: "error",
        });
        return Promise.resolve(false);
      }
      if (data.expenses && data.expenses < 0) {
        this.selectTab("#nav-expenses-tab");
        Swal.fire({
          title: "Error",
          text: "Expenses can't be negative!",
          icon: "error",
        });
        return Promise.resolve(false);
      }
      if (sessionTimeDiff == 0) {
        const vm = this;
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: "btn btn-black ml-4",
            cancelButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });

        return swalWithBootstrapButtons
          .fire({
            title: "Are you sure?",
            text: "You have set the main service to 0 hours, are you sure you want to proceed?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, sure!",
            cancelButtonText: "No, cancel!",
            reverseButtons: true,
          })
          .then((result) => {
            if (result.isConfirmed) {
              return vm.handleUpdateBookWithRateCheck(data, verify);
            }
          });
      } else {
        return this.handleUpdateBookWithRateCheck(data, verify);
      }
    },
    handleUpdateBookWithRateCheck: function (data, verify = false) {
      if (
        data.billable &&
        (!data.provider_rate || parseFloat(data.provider_rate) == 0)
      ) {
        const vm = this;
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: "btn btn-black ml-4",
            cancelButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });

        return swalWithBootstrapButtons
          .fire({
            title: "Are you sure?",
            text: "Provider Rate is zero or empty, are you sure you want to proceed?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, sure!",
            cancelButtonText: "No, cancel!",
            reverseButtons: true,
          })
          .then((result) => {
            if (result.isConfirmed) {
              data.provider_rate = 0;
              return vm.handleBookUpdate(data, verify);
            }
          });
      } else {
        return this.handleBookUpdate(data, verify);
      }
    },
    handleBookUpdate: function (dataToUpdate, verify = false) {
      this.actionInProgress = true;
      return this.$http
        .put("appointments/" + dataToUpdate.id, dataToUpdate)
        .then(
          function (res) {
            this.actionInProgress = false;
            if (res) {
              if (!verify) {
                this.openUpdateTray = false;
                Swal.fire({
                  title: "Appointment Updated",
                  text: "Appointment Updated Successfully",
                  icon: "success",
                });
              }
              calendarApi.refetchEvents();
            }
            return Promise.resolve(true);
          }.bind(this)
        )
        .catch((err) => {
          this.actionInProgress = false;
          if (!err.accessDenied) {
            Swal.fire({
              title: "Error",
              text:
                ((err.response || {}).data || {}).message ||
                (err.data || {}).message ||
                "Something went wrong...",
              icon: "error",
            });
          }
          return Promise.resolve(false);
        });
    },
    handleBook: function () {
      const canadaOffset = dayjs
        .tz(new Date(this.clickedEventDetails_startStr), "America/Toronto")
        .utcOffset();
      const sessionTimeDiff = dayjs(this.clickedEventDetails_endStr).diff(
        this.clickedEventDetails_startStr,
        "s"
      );
      if (sessionTimeDiff < 0) {
        this.selectTab("#nav-home-create-tab");
        Swal.fire({
          title: "Error",
          text: `Session end time is earlier than start time!`,
          icon: "error",
        });
        return Promise.resolve(false);
      } else if (
        sessionTimeDiff == 0 &&
        (this.selectedMainServiceOnCreate.unit == "FT" ||
          this.selectedMainServiceOnCreate.unit == "FLAT")
      ) {
        this.selectTab("#nav-home-tab");
        Swal.fire({
          title: "Error",
          text: `Session end time is equal to start time!`,
          icon: "error",
        });
        return Promise.resolve(false);
      }
      let data = {
        // We need to store unified date in server with GTM+0
        // Then we have to use dayjs to format the time to match browser time zone.
        start_time: dayjs(this.clickedEventDetails_startStr)
          .add(dayjs(this.clickedEventDetails_startStr).utcOffset(), "minute")
          .add(canadaOffset, "minute")
          .utc()
          .format(),
        end_time: dayjs(this.clickedEventDetails_endStr)
          .add(dayjs(this.clickedEventDetails_endStr).utcOffset(), "minute")
          .add(canadaOffset, "minute")
          .utc()
          .format(),
        user_2: this.booking.patient,
        // If provider then set user Id
        user_1: this.user.isProvider
          ? this.user.id
          : parseInt(`${this.booking.provider}`.split("_")[0]),
        memo: this.booking.memo,
        type: this.booking.type,
        primary: this.booking.primary,
        reason: this.booking.reason,
        expenses: this.isBillable ? this.booking.expenses : 0,
        billing_details: this.isBillable ? this.booking.billing_details : "",
        provider_rate: this.booking.provider_rate,
        auto_schedule_zoom: this.booking.auto_schedule_zoom,
        virtual_url: "To Create", // Show popup on checkbox is checked.TODO
        billable: this.isBillable,
        allow_overlap: this.allowOverlap,
        hidden: this.isHidden,
      };

      data.services = this.clickedEventDetails_services.filter(
        (item) => item.selected == true
      );

      let hasEmptyQuantity = false;
      let hasNegativeQuantity = false;
      data.services.forEach((service) => {
        if (!service.qty) {
          hasEmptyQuantity = true;
        } else if (service.qty < 0) {
          hasNegativeQuantity = true;
        }
      });
      if (hasEmptyQuantity || hasNegativeQuantity) {
        this.selectTab("#nav-profile-create-tab");
        return Swal.fire({
          title: "Error",
          text: hasEmptyQuantity
            ? "Quantity in selected sub services is required!"
            : "Quantity in selected sub services can't be negative!",
          icon: "error",
        });
      }
      if (data.expenses && data.expenses < 0) {
        this.selectTab("#nav-expenses-create-tab");
        Swal.fire({
          title: "Error",
          text: "Expenses can't be negative!",
          icon: "error",
        });
        return Promise.resolve(false);
      }

      if (sessionTimeDiff == 0) {
        const vm = this;
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: "btn btn-black ml-4",
            cancelButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });

        return swalWithBootstrapButtons
          .fire({
            title: "Are you sure?",
            text: "You have set the main service to 0 hours, are you sure you want to proceed?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, sure!",
            cancelButtonText: "No, cancel!",
            reverseButtons: true,
          })
          .then((result) => {
            if (result.isConfirmed) {
              return vm.handleCreateBookWithRateCheck(data);
            }
          });
      } else {
        return this.handleCreateBookWithRateCheck(data);
      }
    },
    handleCreateBookWithRateCheck: function (data) {
      if (
        data.billable &&
        (!data.provider_rate || parseFloat(data.provider_rate) == 0)
      ) {
        const vm = this;
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: "btn btn-black ml-4",
            cancelButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });

        return swalWithBootstrapButtons
          .fire({
            title: "Are you sure?",
            text: "Provider Rate is zero or empty, are you sure you want to proceed?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, sure!",
            cancelButtonText: "No, cancel!",
            reverseButtons: true,
          })
          .then((result) => {
            if (result.isConfirmed) {
              vm.actionInProgress = true;
              vm.$http
                .post("appointments", data)
                .then(
                  function (res) {
                    vm.actionInProgress = false;
                    if (res && vm.verifyBookEvent) {
                      vm.approveNewEvent(res.data.data.id);
                    } else if (res) {
                      calendarApi.refetchEvents();
                      vm.close();
                    }
                  }.bind(vm)
                )
                .catch((err) => {
                  vm.actionInProgress = false;
                  if (!err.accessDenied) {
                    Swal.fire({
                      title: "Issue While Creating the Appointment",
                      text:
                        ((err.response || {}).data || {}).message ||
                        (err.data || {}).message ||
                        "Something went wrong...",
                      icon: "error",
                    });
                  }
                });
            }
          });
      } else {
        this.actionInProgress = true;
        this.$http
          .post("appointments", data)
          .then(
            function (res) {
              this.actionInProgress = false;
              if (res && this.verifyBookEvent) {
                this.approveNewEvent(res.data.data.id);
              } else if (res) {
                calendarApi.refetchEvents();
                this.close();
              }
            }.bind(this)
          )
          .catch((err) => {
            this.actionInProgress = false;
            if (!err.accessDenied) {
              Swal.fire({
                title: "Issue While Creating the Appointment",
                text:
                  ((err.response || {}).data || {}).message ||
                  (err.data || {}).message ||
                  "Something went wrong...",
                icon: "error",
              });
            }
          });
      }
    },
    canHaveClinicalNotes: function () {
      if (
        (this.clickedEventDetails_patient || {}).id &&
        this.patients.filter(
          (patient) => patient.user_id == this.clickedEventDetails_patient.id
        ).length
      ) {
        return true;
      }
      return this.isBillable;
    },
    handleSelect: function (arg) {
      if (this.user && this.user.isProvider && !this.patients.length) {
        return Swal.fire({
          title: "Warning",
          text: "Can't create a session without having at least 1 client connected to you.",
          icon: "warning",
        });
      }
      if (this.user && !this.user.isPatient) {
        this.clearEventDetailsData();
        this.booking.start = arg.start;
        this.booking.end = arg.end;
        const difference =
          dayjs(this.booking.end).diff(dayjs(this.booking.start)) /
          (1000 * 60 * 60 * 24);
        if (difference == 1) {
          this.clickedEventDetails_startStr = dayjs(this.booking.start)
            .hour(9)
            .format();
          this.clickedEventDetails_endStr = dayjs(this.booking.start)
            .hour(10)
            .format();
        } else {
          this.clickedEventDetails_startStr = dayjs(
            this.booking.start
          ).format();
          this.clickedEventDetails_endStr = dayjs(this.booking.end).format();
        }
        this.booking.primary = null;
        this.isBillable = true;

        if (this.user.isProvider) {
          this.booking.provider = this.user.id;
          this.showServicesBasedOnSelectedProvider(
            this.user.id + "_false",
            true
          );
        }
        if (this.profilePatientUserId) {
          this.booking.patient = this.profilePatientUserId;

          this.booking.provider = `${this.user.id}_${
            this.user.isAdmin ? "true" : "false"
          }`;
          this.showServicesBasedOnSelectedProvider(this.booking.provider, true);
          this.updateProviderRate(this.booking.provider, this.booking.primary);
        } else {
          this.showServicesBasedOnSelectedProvider(this.booking.provider, true);
        }
        this.getSubServicesByParentServiceId(this.booking.primary, null);
        this.verifyBookEvent = false;
        this.allowOverlap = false;
        this.changesMade = false;
        this.openTray = true;
      }
    },
    handleDateClick: function (arg) {
      if (arg.view.type == "dayGridMonth") {
        calendarApi.changeView("timeGridWeek");
        calendarApi.gotoDate(arg.dateStr);
      }
    },
    createNote: function () {
      const patient = this.patients.filter(
        (patient) => patient.user_id == this.clickedEventDetails_patient.id
      )[0];
      this.$emit("closeModal");
      this.$router.push({
        name: "patients.notes.create",
        params: {
          userId: this.user.id,
          id: patient.id,
          appointmentId: this.clickedEventDetails_id,
        },
      });
    },
    navigateToClinicalNotes: function () {
      const patient = this.patients.filter(
        (patient) => patient.user_id == this.clickedEventDetails_patient.id
      )[0];
      this.$emit("closeModal");
      this.$router.push(`/clients/${patient.id}/notes`);
    },
    joinMeeting: function () {
      window.open(this.clickedEventDetails_meetingUrl, "_blank");
    },
    updateProviderRate: function (providerId_Type, serviceId) {
      let isAdmin = false;
      let providerId = providerId_Type;
      if (providerId_Type != null) {
        const providerSplitValue = `${providerId_Type}`.split("_");

        if (providerSplitValue != null) {
          providerId = `${providerId_Type}`.split("_")[0];
          if (`${providerId_Type}`.split("_")[1] == "true") {
            isAdmin = true;
          }
        }
      }

      if (providerId && serviceId) {
        const selectedService = this.services.filter(
          (service) => service.id == serviceId
        )[0];

        let selectedProvider = this.users.filter(
          (user) => user.id == providerId
        )[0];

        if (!isAdmin && !((selectedProvider || {}).professions || []).length) {
          selectedProvider = this.providers.filter(
            (item) => item.user_id == providerId
          )[0];
        }
        if (!selectedProvider) {
          return;
        }
        const providerProfession = (selectedProvider.professions || []).filter(
          (profession) => profession.id == selectedService.profession_id
        )[0];
        if (providerProfession) {
          if (this.openUpdateTray) {
            this.clickedEventDetails_providerRate = providerProfession.rate;
          } else {
            this.booking.provider_rate = providerProfession.rate;
          }
        }
      }
    },
    getProvider: function (item) {
      let tempItem;
      if (item.provider) {
        tempItem = item.provider;
      } else {
        tempItem = item.user_1 || {};
        tempItem.user_id = tempItem.id;
      }
      return tempItem;
    },
    getPatient: function (item) {
      let tempItem;
      if (item.patient) {
        tempItem = item.patient;
      } else {
        tempItem = item.user_2 || {};
        tempItem.user_id = tempItem.id;
      }
      return tempItem;
    },
    openCloneSessionModal: function (eventId) {
      this.allowOverlap = false;
      this.eventToClone = this.events.filter(
        (eventItem) => eventItem.id == eventId
      )[0];

      if (!this.eventToClone) {
        return Swal.fire({
          title: "Error",
          text: `Something went wrong, please refresh the page and try again`,
          icon: "error",
        });
      }

      let hasInactiveServices = false;
      (this.eventToClone.services || []).forEach((serv) => {
        if (serv.status == "INACTIVE") {
          hasInactiveServices = true;
        }
      });

      if (hasInactiveServices) {
        return Swal.fire({
          title: "Warning",
          text: `Unable to clone events with inactive services`,
          icon: "warning",
        });
      }
      this.cloneFilter = {
        dateRangeOption: "dateRange",
        isSaving: false,
        dateRangeData: {
          weekDay: 0,
          startDate: dayjs(this.eventToClone.start_time).add(1, "day").format(),
          endDate: "",
        },
        minDate: dayjs(this.eventToClone.start_time).add(1, "day").format(),
        customDates: [],
        customDatesIds: [],
      };
      this.$modal.show("clone-session-modal");
    },
    closeCloneSessionModal: function () {
      this.$modal.hide("clone-session-modal");
    },
    sendCloneRequest: function () {
      this.cloneFilter.isSaving = true;
      if (this.cloneFilter.dateRangeOption == "dateRange") {
        const sessionDates = this.getAllSelectedDayInRange();
        this.cloneBook(sessionDates);
      } else if (this.cloneFilter.dateRangeOption == "customDates") {
        const sessionDates = this.getAllSelectedDates();
        this.cloneBook(sessionDates);
      }
    },
    getAllSelectedDayInRange: function () {
      const start = new Date(this.cloneFilter.dateRangeData.startDate);
      const end = new Date(this.cloneFilter.dateRangeData.endDate);
      const day = this.cloneFilter.dateRangeData.weekDay;
      const difference = dayjs(this.eventToClone.end_time).diff(
        this.eventToClone.start_time
      );

      const hours = this.eventToClone.start_time.split("T")[1];
      let current = start;
      const result = [];
      // Shift to next of required days
      current.setDate(current.getDate() + ((day - current.getDay() + 7) % 7));
      // While not exceeding end date, add dates to result array
      while (current <= end) {
        const utcCurrent = `${dayjs(current).format("YYYY-MM-DD")}T${hours}`;
        const canadaOffset = dayjs.tz(current, "America/Toronto").utcOffset();

        const sessionStart = dayjs(new Date(utcCurrent))
          .add(canadaOffset, "minute")
          .utc()
          .format()
          .replace(":00Z", ":00.000000Z");
        const sessionEnd = dayjs(sessionStart)
          .add(difference, "millisecond")
          .utc()
          .format()
          .replace(":00Z", ":00.000000Z");

        result.push({ start_time: sessionStart, end_time: sessionEnd });
        current.setDate(current.getDate() + 7);
      }
      return result;
    },
    getAllSelectedDates: function () {
      const difference = dayjs(this.eventToClone.end_time).diff(
        this.eventToClone.start_time
      );

      const result = [];
      this.cloneFilter.customDates.forEach((item) => {
        const canadaOffset = dayjs
          .tz(new Date(item), "America/Toronto")
          .utcOffset();
        const hours = this.eventToClone.start_time.split("T")[1];
        const current = `${dayjs(item).format("YYYY-MM-DD")}T${hours}`;

        const sessionStart = dayjs(new Date(current))
          .add(canadaOffset, "minute")
          .utc()
          .format()
          .replace(":00Z", ":00.000000Z");

        const sessionEnd = dayjs(sessionStart)
          .add(difference, "millisecond")
          .utc()
          .format()
          .replace(":00Z", ":00.000000Z");
        result.push({ start_time: sessionStart, end_time: sessionEnd });
      });
      return result;
    },
    getAllBulkSelectedDates: function () {
      const result = [];
      this.bulkSessionsFilter.customDates.forEach((item) => {
        const startDateTime = `${dateFormat(
          item,
          "YYYY-MM-DD"
        )}T00:00:00.000000Z`;
        const endDateTime = `${dateFormat(
          item,
          "YYYY-MM-DD"
        )}T23:59:59.000000Z`;

        const canadaOffsetStart = dayjs
          .tz(new Date(startDateTime), "America/Toronto")
          .utcOffset();
        const canadaOffsetEnd = dayjs
          .tz(new Date(endDateTime), "America/Toronto")
          .utcOffset();
        const start_time = dayjs(startDateTime)
          .add(canadaOffsetStart, "minute")
          .utc()
          .format();

        const end_time = dayjs(endDateTime)
          .add(canadaOffsetEnd, "minute")
          .utc()
          .format();
        result.push({ start_time: start_time, end_time: end_time });
      });
      return result;
    },
    cloneBook: function (sessionDates) {
      if (!sessionDates || !sessionDates.length) {
        this.actionInProgress = false;
        this.cloneFilter.isSaving = false;
        const days = [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ];
        if (this.cloneFilter.dateRangeOption == "dateRange") {
          return Swal.fire({
            title: "Warning",
            html: `Selected date range has no <b>${
              days[this.cloneFilter.dateRangeData.weekDay]
            }</b>`,
            icon: "warning",
          });
        }
        return Swal.fire({
          title: "Warning",
          text: "Please select at least one date",
          icon: "warning",
        });
      }
      let data = {
        session_dates: sessionDates,
        user_1: this.getProvider(this.eventToClone).user_id,
        user_2: this.getPatient(this.eventToClone).user_id,
        type: this.eventToClone.type,
        memo: this.cloneSettings.memo ? this.eventToClone.memo : "",
        reason: this.cloneSettings.reason ? this.eventToClone.reason : "",
        expenses: this.cloneSettings.expenses ? this.eventToClone.expenses : 0,
        billing_details: this.cloneSettings.billingDetails
          ? this.eventToClone.billing_details
          : "",
        provider_rate: this.eventToClone.provider_rate,
        billable: this.eventToClone.billable == "1",
        auto_schedule_zoom: !!(
          this.eventToClone.start_url || this.eventToClone.join_url
        ),
        virtual_url: "To Create", // Show popup on checkbox is checked.TODO
        allow_overlap: this.allowOverlap,
      };
      data.services = this.cloneSettings.subServices
        ? this.eventToClone.services
        : [];
      data.primary = (
        this.eventToClone.services.filter(
          (item) => !item.parent_service_id
        )[0] || {}
      ).id;

      data.services.forEach((service, index) => {
        if (service.pivot != null && service.pivot != undefined) {
          service.qty = service.pivot.quantity;
          service.rate = service.pivot.rate;
          service.unit = service.pivot.unit;
          service.taxable = service.pivot.taxable == "1" ? true : false;
          if (!service.description) {
            service.description = service.pivot.description;
          }
        }
        service.description = (service.description || "").substring(0, 500);
        if (index) {
          service.selected = true;
        }
        delete service["pivot"];
      });
      data.services = data.services.filter((item) => item.parent_service_id);
      this.actionInProgress = true;
      this.$http
        .post("appointments-clone", data)
        .then(
          function (res) {
            this.actionInProgress = false;
            if (res) {
              calendarApi.refetchEvents();
              this.$modal.hide("clone-session-modal");
              this.cloneSettings = {
                expenses: false,
                billingDetails: true,
                memo: true,
                reason: true,
                subServices: true,
              };
              Swal.fire({
                title: "Appointment Cloned",
                text: "Appointment Cloned Successfully",
                icon: "success",
              });
            }
          }.bind(this)
        )
        .catch((err) => {
          this.actionInProgress = false;
          if (!err.accessDenied) {
            let text =
              ((err.response || {}).data || {}).message ||
              (err.data || {}).message ||
              "Something went wrong...";
            const errors =
              ((err.response || {}).data || {}).errors ||
              (err.data || {}).errors ||
              [];
            if (errors.length) {
              text =
                'Below sessions haven\'t been cloned due to overlapping with other sessions for same user<br /><div class="border rounded mt-4 py-3"><b>';
              errors.forEach((error) => {
                const canadaOffset = dayjs
                  .tz(new Date(error), "America/Toronto")
                  .utcOffset();
                const sessioDate = dayjs(error)
                  .add(-1 * canadaOffset, "minute")
                  .utc()
                  .format("YYYY-MM-DD hh:mm A");
                text = `${text}${sessioDate}<br>`;
              });
              text = `${text}</div></b>`;
            }
            Swal.fire({
              title: "Issue While Cloning Appointment",
              html: text,
              icon: "error",
            });
          }
          calendarApi.refetchEvents();
          this.$modal.hide("clone-session-modal");
        });
    },
    canEnable: function (userId) {
      if ((this.user || {}).isProvider) {
        if (this.user.id !== userId) {
          return false;
        }
      }
      return true;
    },
    dragMouseDown(event) {
      const draggableContainer = this.$refs.draggableContainer.parentElement;
      let shiftX =
        event.clientX - draggableContainer.getBoundingClientRect().left;
      let shiftY =
        event.clientY - draggableContainer.getBoundingClientRect().top;

      function moveAt(pageX, pageY) {
        draggableContainer.style.left = pageX - shiftX + "px";
        draggableContainer.style.top = pageY - shiftY + "px";
      }

      function onMouseMove(event) {
        moveAt(event.pageX, event.pageY);
      }

      window.addEventListener("mousemove", onMouseMove);

      window.onmouseup = function () {
        window.removeEventListener("mousemove", onMouseMove);
        window.onmouseup = null;
      };
    },
    dragAvailabilityMouseDown(event) {
      const draggableContainer =
        this.$refs.draggableAvailabilityContainer.parentElement;
      let shiftX =
        event.clientX - draggableContainer.getBoundingClientRect().left;
      let shiftY =
        event.clientY - draggableContainer.getBoundingClientRect().top;

      function moveAvailabilityAt(pageX, pageY) {
        draggableContainer.style.left = pageX - shiftX + "px";
        draggableContainer.style.top = pageY - shiftY + "px";
      }

      function onMouseAvailabilityMove(event) {
        moveAvailabilityAt(event.pageX, event.pageY);
      }

      window.addEventListener("mousemove", onMouseAvailabilityMove);

      window.onmouseup = function () {
        window.removeEventListener("mousemove", onMouseAvailabilityMove);
        window.onmouseup = null;
      };
    },
    updateHiddenValue: function (eventId) {
      this.eventToHide = this.events.filter(
        (eventItem) => eventItem.id == eventId
      )[0];
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-black ml-4",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons
        .fire({
          text: `Are you sure you want to ${
            !this.eventToHide.hidden ? "hide" : "unhide"
          } this session?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Yes, ${
            !this.eventToHide.hidden ? "hide" : "unhide"
          } it!`,
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$http
              .put("appointments/" + eventId + "/update-hidden", {
                hidden: !this.eventToHide.hidden,
              })
              .then(
                function (res) {
                  if (res) {
                    Swal.fire({
                      title: "Success",
                      text: `Session ${
                        !this.eventToHide.hidden ? "hidden" : "unhidden"
                      } successfully`,
                      icon: "success",
                    });
                    this.eventToHide.hidden = !this.eventToHide.hidden;
                    calendarApi.refetchEvents();
                  }
                }.bind(this)
              )
              .catch((err) => {
                if (!err.accessDenied) {
                  Swal.fire({
                    title: "Error",
                    text:
                      ((err.response || {}).data || {}).message ||
                      (err.data || {}).message ||
                      "Something went wrong...",
                    icon: "error",
                  });
                }
              });
          }
        });
    },
    openUserAvailabilityModal: function (userId) {
      this.availabilityUserId = parseInt(`${userId}`.split("_")[0]);
      this.$modal.show("availability-session-modal");
    },
    closeUserAvailabilityModal: function () {
      this.availabilityUserId = null;
      this.$modal.hide("availability-session-modal");
    },
  },
};
</script>

<style lang="scss">
.checkbox-input {
  box-shadow: 0 0 0 1px #0859a985;
  transform: scale(0.45);
  width: 40px;
  margin-top: -5px;
}
.pointer-none {
  pointer-events: none;
}
.bulk-sessions-modal {
  .main-modal-container {
    cursor: move;
    min-height: 450px;
  }
  .v--modal-box {
    overflow: visible;
    min-height: 450px;
    max-height: 100%;
    transition: none;

    @media (max-width: 768px) {
      max-height: 96vh;
      overflow: hidden;
      .card-body {
        max-height: calc(96vh - 47px);
        overflow-y: auto;
      }
    }
  }
}
.availability-session-modal {
  &.v--modal-overlay {
    z-index: 2001;
  }
  .card-body {
    cursor: move;
    height: calc(100% - 35px);
    overflow: auto;
    padding: 12px;
  }
  .availability-page {
    margin-top: 0 !important;
  }

  .day-checkbox-container {
    padding-left: 0;
    input {
      display: none;
    }
  }
  .form-control:disabled {
    opacity: 1;
  }
}
.table {
  thead {
    th.large-font {
      font-size: 20px !important;
    }
  }
}
.atcb-list-wrapper.atcb-dropoverlay {
  top: 240px !important;
  position: fixed;
}
.calendar-print-table-header {
  height: 240px;
}
.calendar-print-table-footer {
  height: 140px;
}

.calendar-print-header {
  height: 220px;
  display: none;
  overflow: hidden;

  .company-logo {
    max-width: 160px;
    max-height: 160px;
  }
}
.calendar-print-footer {
  height: 120px;
  display: none;
}
.calendar-print-header,
.calendar-print-footer {
  position: fixed;
  width: 100%;
  left: 0;
}
.calendar-print-header {
  top: 0;
  border-bottom: 1px solid;
}
.calendar-print-footer {
  bottom: 0;
  border-top: 1px solid;
}

.calendar-wrapper {
  position: relative;
  background-color: inherit;

  .fc-view {
    background-color: white;
  }
  .fc-scrollgrid-section-liquid {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07),
      0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07),
      0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);
  }

  .verified-card {
    .verified-mark {
      background-color: white;
      z-index: 1000;
      color: green;
      padding: 0;
      margin: 0;
      border-radius: 50%;
    }
    &.non-billable-card {
      .fc-daygrid-event-dot {
        border-color: darkblue !important;
      }
      .verified-mark {
        color: darkblue;
      }
    }
  }
  .pending-card {
    .fc-daygrid-event-dot {
      border-color: purple !important;
    }
  }
  .non-billable-card {
    .fc-daygrid-event-dot {
      border-color: darkred !important;
    }
  }
  .color-button {
    padding-left: 20px !important;
  }
  .fc-scrollgrid-section-header {
    overflow: hidden;
    border: none;
    background: #e7efff;

    .fc-scroller {
      overflow: hidden !important;
    }
    scrollbar-width: none !important;
    -ms-overflow-style: none !important;
    &::-webkit-scrollbar {
      width: 0 !important;
      background: transparent !important;
      height: 0 !important;
    }
  }
  table {
    border: none;
  }

  .fc-toolbar-title {
    font-weight: 900;
    font-size: 45px;
    color: #062157;
  }

  .fc-scroller-harness {
    overflow: hidden;

    .fc-non-business {
      background-color: #f6f7fb;
    }

    .fc-col-header {
      overflow: hidden;
      height: 44px;
      border: none;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;

      th:first-of-type {
        opacity: 0.56;
      }
      th:last-of-type {
        opacity: 0.56;
      }

      tr {
        background-color: #0e43ad;
      }
      th {
        text-align: right;
        border: none;
        padding-right: 12px;
        vertical-align: middle;
        margin: 0px;
        color: white !important;
      }
    }
  }

  .btn-primary {
    font-weight: 600;
    &::first-letter {
      text-transform: uppercase;
    }

    &:not(:disabled) {
      &:not(.disabled) {
        &:active {
          color: #0e43ad;
          background-color: #fff;
          border: 2px solid #f0f5ff;
          border-radius: 8px;
        }
      }
      &:not(.disabled).active {
        color: #0e43ad;
        background-color: #fff;
        border: 2px solid #f0f5ff;
        border-radius: 8px;
      }
    }
  }

  .print-btn {
    position: absolute;
    left: 367px;
    line-height: 1;
    margin-top: 8px;
  }

  .total-hours-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    right: 266px;
    font-size: 18px;
    color: #062157;
  }
  th {
    .fc-day {
      border-left: 1px;
      border-right: 1px;
      text-align: right;
      padding-right: 12px;
    }
  }
  .fc-toolbar-chunk {
    .btn-group {
      background-color: #f0f5ff;
      border-radius: 8px;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
    .fc-next-button,
    .fc-today-button,
    .fc-prevYear-button,
    .fc-nextYear-button,
    .fc-prev-button {
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
  }
  .btn-primary {
    background-color: #f0f5ff;
    border-radius: 10px;
    color: #062157;
    border: 2px;
    border-color: #f0f5ff;
  }
}
.calendar-wrapper {
  position: relative;
  .loader {
    position: absolute;
    top: 130px;
    left: 0;
    right: 0;
    bottom: 0;
    align-items: flex-start;
    padding-top: 180px;
  }
}
.loader {
  &.event-loader {
    width: 500px;
    right: 0;
    top: 0;
  }
}

.fc {
  .fc-popover {
    max-height: 400px;
    overflow: overlay;
    z-index: 9;
  }
}
.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  margin-left: 5%;
  margin-right: 5%;
}

.month-event-container {
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}
.deleted-session {
  background-position: center;

  .event-time-container,
  .event-user-info {
    opacity: 0.7;
  }
}
.month-event-container {
  width: 100%;
  border-radius: 4px;
  font-weight: bold;
  background-color: white;
  color: #12263f;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  text-shadow: 0 0 4px #ffffff;
  transition: 0.3s ease-in-out;
  &:hover {
    opacity: 0.8;
  }
  .secret-session {
    position: absolute;
    top: -4px;
    left: -4px;
  }

  .verified-mark {
    color: darkgreen;
    position: absolute;
    right: -4px;
    top: -2px;
    z-index: 9999;
    background: white;
  }

  .event-service {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 4px;
    padding-right: 12px;
    padding-top: 8px;
    word-break: break-word;
    text-align: center;
    min-height: 2.8rem;
    line-height: 1.2rem;
    word-break: break-word;
    white-space: pre-wrap;
    font-size: 18px;
  }
  .event-time-container {
    flex-wrap: wrap;
    align-items: center;
    margin-top: 8px;
    padding-right: 36px;
    font-size: 18px;
    font-weight: normal;
    max-width: 100%;
    .event-start-end-time {
      color: black;
    }
  }
  .event-time-diff {
    color: #062157;
    font-weight: bold;
    display: inline-block;
    margin-left: 5px;
    text-shadow: 0 0 4px white;
    font-size: 18px;

    &.empty {
      color: transparent !important;
      text-shadow: none;
    }
  }
  .event-user-info {
    padding-left: 4px;
    padding-right: 4px;
    margin-bottom: 8px;
    font-size: 17px;
    margin-top: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .fc-daygrid-event-dot {
    margin-left: 16px;
    box-shadow: 0 0 6px 0 #ffffff;
  }
}
.provider-name,
.client-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.right-secret-session {
  position: absolute;
  top: 4px;
  right: 1px;
}
@media (max-width: 574px) {
  .calendar-wrapper {
    .loader {
      top: 103px;
      height: calc(100% - 103px);
      align-items: center;
      padding-top: 0;
    }
    .print-btn {
      right: 4px;
      left: initial;
      top: 30px;
    }
    .total-hours-info {
      right: 4px;
      top: 146px;
    }
    .fc-direction-ltr {
      overflow-x: auto;
      padding-left: 4px;

      > .fc-header-toolbar {
        margin-bottom: 70px;
      }

      .fc-toolbar-chunk {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;

        .btn-group {
          margin-bottom: 16px;
        }
      }
      .fc-view-harness {
        min-width: 900px;
      }
    }
    .fc-scrollgrid-section-liquid {
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07),
        0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07),
        0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);
    }
  }
}

@media (max-width: 1244px) {
  .calendar-wrapper {
    .total-hours-info {
      right: 20px;
    }
  }
}

.fc-media-screen .fc-timegrid-event-harness,
.fc-daygrid-event {
  cursor: pointer;
}
@media print {
  @page {
    size: 330mm 400mm;
  }
  .calendar-wrapper {
    margin-top: 230px;
  }

  .calendar-table {
    margin-top: -110px;
    break-inside: avoid;
    page-break-inside: avoid;
  }

  .fc-toolbar-chunk .btn-group,
  .fc-toolbar-chunk .fc-today-button,
  .fc-toolbar-chunk .btn {
    display: none;
  }
  .calendar-print-header,
  .calendar-print-footer {
    display: block;
  }
}
.calendar-clone-icon {
  color: #183153;
  position: absolute;
  right: 0;
  &:hover,
  &:focus {
    transition: all 0.2s ease-in-out;
    transform: scale(1.2);
  }
}
.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  background-color: white !important;
  border: 0;
}
.fc-daygrid-dot-event:hover,
.fc-daygrid-dot-event.fc-event-mirror {
  background-color: rgba(220, 220, 220, 0.1);
}

@media (max-width: 1244px) {
  .fc-header-toolbar {
    display: block !important;

    .btn-group {
      margin-bottom: 12px;
    }
  }
  .fc-toolbar-title {
    font-size: 32px !important;
    margin-bottom: 4px !important;
  }
  .print-btn {
    left: auto;
  }
}
</style>

<style scoped lang="scss">
.h-406 {
  height: 406px;
}

.right-0 {
  right: 0 !important;
}
.left-auto {
  left: auto !important;
}

.saving-note-container {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 112px;
  left: 0;
  right: 0;
  text-align: center;
  .saving-note {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb;
  }
}
.radio-btn-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  .radio {
    margin: 0 0.25rem;

    label {
      background: #fff;
      border: 1px solid #ddd;
      border-radius: 5px;
      width: 50px;
      height: 42px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: #444;
      transition: box-shadow 400ms ease;
      &:hover {
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
          0 2px 10px 0 rgba(0, 0, 0, 0.12);
      }
    }

    input[type="radio"] {
      display: none;
    }

    input[type="radio"]:checked + label {
      background: #275981;
      color: #fff;
      border-color: #2196f3;
    }
  }
}

@media (max-width: 767px) {
  .custom-date-mobile {
    margin-left: -54px;
    width: calc(100% + 54px);
    max-width: calc(100% + 54px);
  }
}
</style>
